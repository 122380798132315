import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {BsPlus} from 'react-icons/bs';
import {Link} from "react-router-dom";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const Strength = ({title, subtitle, text, img, full, project, sticky, url}) => {

    return (
        <StyledStrength className={'project-box'}>
            <div className="about-section__bottom__single__inner">
                <Link to={`/project/${url}`}></Link>
                <div className="about-section__bottom__single__inner__img">
                    <Img src={img}/>
                </div>

                <div className="circle">
                    <BsPlus/>
                </div>
                <p>{reactHtmlParser(text)}</p>
                <h5>{title}</h5>
                <h2>{subtitle}</h2>
                <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414"
                         viewBox="0 0 11.207 11.414">
                        <g id="Group_15992" data-name="Group 15992" transform="translate(0.5 0.85)">
                            <line id="Line_3858" data-name="Line 3858" x2="5" y2="5"
                                  transform="translate(5 -0.143)" fill="none" stroke="#f9f9f9"
                                  stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_3859" data-name="Line 3859" y1="5" x2="5"
                                  transform="translate(5 4.857)" fill="none" stroke="#f9f9f9"
                                  stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_3860" data-name="Line 3860" x1="10"
                                  transform="translate(0 4.857)" fill="none" stroke="#f9f9f9"
                                  stroke-linecap="round" stroke-width="1"/>
                        </g>
                    </svg>

                </div>
            </div>
        </StyledStrength>
    );
};

const StyledStrength = styled.div`
  .about-section__bottom__single__inner {
    position: relative;
    padding-top: calc(370 / 370 * 100%);
    overflow: hidden;
    border-right: 1px solid #FFF;
    //margin-bottom: 30px;
    &:after {
      content: '';
      background-color: #3CB649;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 0%;
      width: 100%;
      transition: .4s ease;
      //mix-blend-mode: multiply;
    }

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0px;
      z-index: 5;
    }

    &__img {
      &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);;
        mix-blend-mode: multiply;
        opacity: 1;
        transition: .8s ease;
        transition-delay: .3s;
      }
    }

    p {
      position: absolute;
      left: 30px;
      right: 30px;
      top: 70px;
      color: #ffffff;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      transform: translateY(-100%);
      transition: all .5s ease;
      opacity: 0;
    }

    .img-top {
      position: absolute;
      top: 40px;
      left: 30px;
      z-index: 2;
      opacity: 1;
      transition: .3s ease;
      height: 60px;
    }

    .circle {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 50px;
      width: 50px;
      border: 1px solid #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      opacity: 1;
      transition: .3s ease;

      svg {
        color: #ffffff;
        font-size: 25px;
        transition: .3s ease;
      }
    }

    h5 {
      position: absolute;
      bottom: 30px;
      left: 40px;
      right: 40px;
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;
      color: #FAFAFA;
      z-index: 2;
      transition: all .5s ease;

    }

    .icon {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 1;
      transition: all .8s ease;
      transform: rotate(0deg);

      svg {
        li {
          transition: all .8s ease;

        }
      }

    }

    h2 {
      position: absolute;
      z-index: 1;
      top: 40px;
      left: 40px;
      font-weight: 500;
      color: #FAFAFA;
      transition: all .5s ease;

    }

  }

  &:hover {
    .about-section__bottom__single__inner {
      &:after {
        height: 0;
      }

      .icon {
        transform: rotate(-45deg);

        svg {
          line {
            stroke: #F9F9F9;
            fill: #F9F9F9;
          }
        }
      }

      h2 {
        top: -10px;
        opacity: 0;
      }

      h4 {
        color: #FCFEFF !important;
      }

      p {
        transform: none;
        opacity: 1;
        color: #FCFEFF;
      }


      &__img:after {
        opacity: 1;
        transition-delay: 0s;
      }

      .img-top {
        transform: translateY(-20px);
        opacity: 0;
      }

      .circle {
        transform: scale(1.9);
        opacity: 0;

        svg {
          transform: rotate(50deg);
        }
      }

    }

  }

`;

export default React.memo(Strength);
