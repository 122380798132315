import React from 'react';
import styled from 'styled-components';
import {hover} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";


const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor,
                    hoverColor,
                    href
                }) => {


    return (
        <StyledBtn className={`dc-btn fade-up`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hoverColor={hoverColor}
                   onSubmit={onSubmit}
                   href={href}
        >
            {src ? (
                <Link to={src || '/'}>
                    <span> {text}  </span>
                </Link>
            ) : (
                <a target={target || '_self'} href={href}>
                    <span>{text}</span>
                </a>
            )}
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '44'}px;
    cursor: pointer;

    a {
      display: flex;
      width: fit-content;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || '16'}px;
      font-weight: ${props => props.fontWeight || 500};
      margin: 0;
      line-height: ${props => props.lineHeight || '20'}px;
      background-color: ${props => props.background || `#1C1718`};
      position: relative;
      border: 1px solid ${props => props.borderColor || "#FCFEFF"};
      border-radius: ${props => props.borderRadius || '40'}px;
      overflow: hidden;
      z-index: 0;
      transition: border .3s ease;
      padding: 6px 32px;
      box-sizing: border-box;

      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: color .3s ease;
        color: ${props => props.color || '#FCFEFF'};
        svg{
          margin-left: 10px;
          path{
            transition: all .3s ease;
          }
        }
      }


      &:before {
        bottom: 0;
        content: "";
        display: block;
        position: absolute;
        right: 0;
        left: 0;
        transition: height 0.5s ease-in-out;
        width: 100%;
        z-index: -1;
        background-color: ${p => p.hoverBackground || "#3CB649"};
        height: 0;
        border-radius: ${props => props.borderRadius || '20px'} !important;
      }

      &:hover {
        border-color: ${hover};

        span {
          color: ${p => p.hoverColor || '#FCFEFF'};
          svg{
            path{
              fill: ${p => p.hoverColor || '#FCFEFF'};;
            }
          }
        }

        &:before {
          height: 100%;
        }
      }

      &:focus {
        color: #222222;
      }
    }
  }

`;


export default React.memo(Button);
