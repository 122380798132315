import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({img, title, deg}) => {
    return (
        <StyledComponent className={'team-single'}>

            <div className="team-single__img">
                <Img src={img}/>
            </div>
            <div className="team-single__content">
                <h6>{reactHtmlParser(title)}</h6>
                <p>{reactHtmlParser(deg)}</p>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  //margin-bottom: 80px;
  position: relative;
  cursor: pointer;

  .team-single__img {
    position: relative;
    padding-top: calc(370 / 370 * 100%);
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }

  .team-single__content {
    margin-top: 20px;

    h6 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      //margin-bottom: 2px;
      color: #25292C;
    }

    p {
      color: rgb(37, 41, 44, 0.5);
    }
  }

  &:hover {
    .team-single__img img {
      transform: scale(1.04);
      transition: all 0.5s ease-in-out;
    }
  }
`;

export default MyComponent;
