import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import aboutReducer from "../redux/about";
import clientReducer from "../redux/clients";
import careerReducer from "../redux/career";
import contactReducer from "../redux/contact";
import educateReducer from "../redux/educate";
import refundReducrer from "../redux/refund";
import faqReducer from "../redux/faq";
import trackingReducer from "../redux/tracking";
import mediaReducer from "../redux/media";
import costReducer from "../redux/cost";
import productReducer from "../redux/product";
import projectReducer from "../redux/project";
import menuReducer from "../redux/menu";
import footerReducer from "../redux/footer";
import formReducer from "../redux/form";


const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,
        homeReducer,
        aboutReducer,
        clientReducer,
        careerReducer,
        contactReducer,
        educateReducer,
        refundReducrer,
        faqReducer,
        trackingReducer,
        mediaReducer,
        costReducer,
        projectReducer,
        productReducer,
        menuReducer,
        footerReducer,
        formReducer

    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

