import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "./Img";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {fetchPosts} from "../api/redux/menu";
import {useDispatch, useSelector} from "react-redux";
import {gsap} from 'gsap';

const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])

    const currentYear = new Date().getFullYear();




    const phone = data?.info?.phone;
    const arrayOfNumbers = phone ? phone?.split(', ') : '';

    return (
        <StyledComponent offset={offset}>
            <Container fluid className={'footer'}>
                <Row className={'footer__top'}>
                    <Col md={8}>
                        <div className={'footer__top__left'}>
                            <Col md={6} className={'footer__top__left__info'}>
                                <div className={'footer__top__left__info__top'}>
                                    {
                                        data?.info?.hotline &&
                                        <h5><a href={`tel:${data?.info?.hotline}`} target='_blank'>
                                            {data?.info?.hotline}</a></h5>
                                    }

                                    <ul>
                                        {
                                            arrayOfNumbers && arrayOfNumbers?.length > 0 &&
                                            arrayOfNumbers?.map((e,i)=>{
                                                return (
                                                    <li key={i}>
                                                        <a href={`tel:${e}`}>{e}</a>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className={'footer__top__left__info__bottom'}>
                                    <p>Contact</p>
                                    <a href={data?.info?.url} target='_blank'>
                                        <h6 className={'address'}>
                                            {data?.info?.address}
                                        </h6>
                                    </a>
                                </div>
                            </Col>
                            <Col md={6} className={'footer__top__left__explore'}>
                                <p>Explore</p>
                                <ul>
                                    <li>
                                        <Link to={'/about'}>Concord RMC</Link>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <Link to="/about#team">*/}
                                    {/*        Management Team*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    <li>
                                        <Link to={'/product'}>Products</Link>
                                    </li>
                                    <li>
                                        <Link to={'/project'}>Projects</Link>
                                    </li>
                                    <li>
                                        <Link to={'/media'}>Media Center</Link>
                                    </li>
                                    <li>
                                        <Link to={'/career'}>Career</Link>
                                    </li>
                                    <li>
                                        <Link to={'/refund-policy'}>
                                            Refund Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/contact'}>Contact</Link>
                                    </li>
                                </ul>
                            </Col>
                        </div>
                        <div className={'footer-mid'}>
                            <Col md={6} className={'footer-logo'}>
                                <Link to={'/'}>
                                    <img src={'/images/static/logo-color.svg'}/>
                                </Link>

                            </Col>
                            <Col md={6}>
                                <ul>
                                    {
                                        data?.social?.facebook &&
                                        <li><a className={'hover'} href={data?.social?.facebook}
                                               target={"_blank"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="7.847" height="14.65"
                                                 viewBox="0 0 7.847 14.65">
                                                <path id="Path_2115" data-name="Path 2115"
                                                      d="M1206.12,104.34l.406-2.652h-2.544v-1.72a1.325,1.325,0,0,1,1.495-1.432h1.157V96.278a14.1,14.1,0,0,0-2.053-.179,3.237,3.237,0,0,0-3.465,3.569v2.021h-2.329v2.652h2.329v6.409h2.866V104.34Z"
                                                      transform="translate(-1198.787 -96.099)" fill="#fff"/>
                                            </svg>

                                        </a></li>
                                    }

                                    {
                                        data?.social?.twitter &&
                                        <li>
                                            <a className={'hover'} href={data?.social?.twitter} target={"_blank"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10.515" height="10.747"
                                                     viewBox="0 0 10.515 10.747">
                                                    <path id="Path_8984" data-name="Path 8984"
                                                          d="M19.443,4.55,23.358,0H22.43l-3.4,3.951L16.316,0H13.185L17.29,5.975l-4.105,4.772h.928L17.7,6.574l2.867,4.172H23.7l-4.258-6.2ZM18.172,6.027l-.416-.595L14.447.7h1.425l2.671,3.821.416.595L22.43,10.08H21.006L18.172,6.028Z"
                                                          transform="translate(-13.185)" fill="#fcfeff"/>
                                                </svg>
                                            </a>
                                        </li>
                                    }

                                    {
                                        data?.social?.linkedin &&
                                        <li>
                                            <a className={'hover'}
                                               href={data?.social?.linkedin}
                                               target={"_blank"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10.515" height="10.747"
                                                     viewBox="0 0 8.776 8.776">
                                                    <g id="_x31_0.Linkedin" transform="translate(-10 -10)">
                                                        <path id="Path_8981" data-name="Path 8981"
                                                              d="M43.972,41.3V38.087c0-1.58-.34-2.786-2.183-2.786a1.9,1.9,0,0,0-1.722.943h-.022v-.8H38.3V41.3h1.821V38.394c0-.768.143-1.5,1.086-1.5s.943.867.943,1.547V41.29h1.821Z"
                                                              transform="translate(-25.195 -22.524)" fill="#fcfeff"/>
                                                        <path id="Path_8982" data-name="Path 8982"
                                                              d="M11.3,36.6h1.821v5.858H11.3Z"
                                                              transform="translate(-1.157 -23.682)" fill="#fcfeff"/>
                                                        <path id="Path_8983" data-name="Path 8983"
                                                              d="M11.053,10a1.059,1.059,0,1,0,1.053,1.053A1.053,1.053,0,0,0,11.053,10Z"
                                                              fill="#fcfeff"/>
                                                    </g>
                                                </svg>

                                            </a>
                                        </li>
                                    }

                                    {
                                        data?.social?.instagram &&
                                        <li><a href={data?.social?.instagram} className={'hover'}
                                               target={"_blank"}>
                                            <svg id="Group_1419" data-name="Group 1419"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="14.56" height="14.56" viewBox="0 0 14.56 14.56">
                                                <path id="Path_2109" data-name="Path 2109"
                                                      d="M1095.77,105.945a.854.854,0,1,0,.853.854A.854.854,0,0,0,1095.77,105.945Z"
                                                      transform="translate(-1084.635 -103.346)" fill="#fff"/>
                                                <path id="Path_2110" data-name="Path 2110"
                                                      d="M1082.64,108.605a3.586,3.586,0,1,0,3.586,3.586A3.59,3.59,0,0,0,1082.64,108.605Zm0,5.882a2.3,2.3,0,1,1,2.3-2.3A2.3,2.3,0,0,1,1082.64,114.488Z"
                                                      transform="translate(-1075.301 -104.911)" fill="#fff"/>
                                                <path id="Path_2111" data-name="Path 2111"
                                                      d="M1080.119,114.188h-5.813a4.379,4.379,0,0,1-4.374-4.374V104a4.378,4.378,0,0,1,4.374-4.373h5.813a4.378,4.378,0,0,1,4.374,4.373v5.813A4.379,4.379,0,0,1,1080.119,114.188ZM1074.306,101a3.007,3.007,0,0,0-3,3v5.813a3.007,3.007,0,0,0,3,3h5.813a3.007,3.007,0,0,0,3-3V104a3.007,3.007,0,0,0-3-3Z"
                                                      transform="translate(-1069.932 -99.628)" fill="#fff"/>
                                            </svg>
                                        </a></li>
                                    }

                                    {
                                        data?.social?.youtube &&
                                        <li><a href={data?.social?.youtube}
                                               target={"_blank"} className={'hover'} target={'_blank'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.822" height="8.975"
                                                 viewBox="0 0 12.822 8.975">
                                                <path id="Path_2114" data-name="Path 2114"
                                                      d="M1146.9,113.13a2.813,2.813,0,0,0-2.813-2.813h-7.195a2.813,2.813,0,0,0-2.813,2.813v3.348a2.813,2.813,0,0,0,2.813,2.813h7.195a2.813,2.813,0,0,0,2.813-2.813Zm-4.231,1.925-3.226,1.6c-.126.068-.556-.023-.556-.167v-3.276c0-.146.433-.237.56-.165l3.089,1.68C1142.661,114.8,1142.8,114.985,1142.666,115.056Z"
                                                      transform="translate(-1134.075 -110.317)" fill="#fff"/>
                                            </svg>

                                        </a></li>
                                    }


                                </ul>
                            </Col>
                        </div>
                    </Col>
                    <Col md={4} sm={12} className={'footer__top__right'}>
                        <div className={'map'}>
                            <a href={data?.info?.url} target={'_blank'}>
                                <Img src={data?.info?.img}/>
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row className={'footer__bottom'}>
                    <Col md={4}>
                        <p>© {currentYear} Concord Ready-mix & Concrete Products LTD.
                            All Rights Reserved.</p>
                    </Col>
                    <Col md={4}>
                        <p>Designed & Developed by <a href={'https://dcastalia.com/'} target='_blank'>Dcastalia</a></p>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .footer {
    width: 100%;
    //padding-left: ${props => props.offset ? props.offset  + 'px' : '180px'};
    padding-right: 0;
    &__top {
      margin: 0;
      //width: 100%;
      
      

      &__left {
        width: 100%;
        padding-top: 80px;
        padding-bottom: 80px;
        display: flex;

        &__info {
          padding-left: 0;

          &__top {
            ul{
              li{
                margin-bottom: 10px;
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
            h5 {
              margin-bottom: 20px;
              
              a {
                font-size: 24px;
                line-height: 28px;
                font-weight: 500;
                color: #3CB649;
              }
            }
          }

          &__bottom {
            margin-top: 40px;
            margin-bottom: 80px;

            p {
              margin-bottom: 20px;
              color: #25292C;
              opacity: 50%;
            }
          }
          @media(max-width: 767px){
            padding-left: 0 !important;
          }
        }

        &__explore {
          p {
            margin-bottom: 20px;
            color: #25292C;
            opacity: 50%;
          }

          ul {
            li {
              margin-bottom: 10px;
              &:last-child{
                margin-bottom: 0;
              }
              a {

              }
            }
          }
          @media(max-width: 767px){
            padding-right: 0 !important;
          }
        }
      }

      &__right {
        padding: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .footer-mid {
        position: relative;
        display: flex;
        padding-top: 40px;
        padding-bottom: 40px;

        ul {
          display: flex;
          li {
            a {
              height: 30px;
              width: 30px;
              border-radius: 50%;
              background-color: #004185;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &:not(:nth-last-of-type(1)) {
              margin-right: 15px;
            }
          }
        }

        .footer-logo {
          padding-left: 0;
        }

        &:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          background-color: #004185;
          height: 0.25px;
          width: calc(100% - 100px);
        }
        @media(max-width: 767px){
          display: block;
          &:before{
            width: 100%;
          }
          ul{
            margin-top: 20px;
          }
          .col-md-6{
            padding: 0;
          }
        }
        
      }
    }
    &__bottom{
      padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '180px'};
      padding-right: ${props => props.offset ? props.offset  + 'px' : '180px'};
      padding-top: 20px;
      padding-bottom: 20px;
      position: relative;
      &:before{
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        background-color: #004185;
        height: 0.25px;
        width: calc(100% - 15px);
        left: ${props => props.offset ? props.offset + 15 + 'px' : '180px'};
        @media(max-width: 767px){
          left: 0;
        }
      }
      .col-md-4{
        padding-left: 0;
        @media(max-width: 767px){
          padding-left: 15px;
        }
        &:first-child{
          p{
            padding-left: 0px !important;
          }
        }
        &:last-child{
          p{
            padding-left: 40px !important;
          }
        }
      }
      p{
       padding-left: 25px !important;
        font-size: 12px;
        line-height: 16px;
        a{
          font-size: 12px;
          line-height: 16px;
        }
        @media(max-width: 767px){
          margin-bottom: 10px;
        }
      }
      
      @media(max-width: 767px){
        padding: 15px;
      }
    }
    @media(max-width: 767px){
      padding-left: 0;
      &__top{
        &__left{
          &__info{
            padding-left: 15px;
          }
          &__explore{
            padding-left: 15px;
          }
        }
        &__right{
          height: 375px;
        }
      }
      &__bottom{
        padding-left: 15px;
        &:before{
          width: 100% !important;
        }
        .col-md-4{
          p{
            padding-left: 0 !important;
          }
          &:last-child{
            p{
              padding-left: 0!important;
            }
          }
        }
      }
    }
  }
  .col-md-8{
    padding-left: ${props => props.offset ? props.offset  + 'px' : '180px'};
    @media(max-width: 767px){
      padding-left: 15px;
    }
  }
`;

export default React.memo(MyComponent);
