import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const SplitUp = () => {
    const location = useLocation();
    const getPost = useSelector(state => state);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger, SplitText);

        const animate = () => {
            document.fonts.ready.then(() => {
                gsap.utils.toArray('.split-up').forEach((element) => {
                    const splitText = new SplitText(element, {
                        type: "lines,words,chars",
                        linesClass: "split-line"
                    });

                    gsap.from(splitText.lines, {
                        y: '100%',
                        opacity: 0,
                        duration: 0.5,
                        ease: 'power4.out',
                        stagger: 0.1,
                        scrollTrigger: {
                            trigger: element,
                            start: "clamp(top bottom-=100)",
                            end: "clamp(bottom top+=100)",
                            toggleActions: "play none none reverse",
                            once: true
                        }
                    });
                });
            });
        };

        animate();

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            gsap.killTweensOf('.split-line');
        };
    }, [location.pathname, getPost]);

    return null;
};

/*
how to use:
- add 'split-up' class on the tag like(h1,h2,h3,p ... etc)
*/