import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {text} from "../../styles/globalStyleVars";
import Media from "../media/Media";
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";


const MyComponent = ({data}) => {



    const [activeItem, setActiveItem] = useState('All');
    const [selectType, setSelectType] = useState('All')
    const [filteredData, setFilteredData] = useState('');

// Handle initial selection when the component mounts
    useEffect(() => {
        handleItemClick('All');
    }, []);
    const handleType = (e) => {
        setSelectType(e);
        // Filter the data when the select dropdown changes
        filterData(e);
    };

    const handleItemClick = (item) => {
        setActiveItem(item);
        filterData(item);
        // Filter the data when an item is clicked
        // setTimeout(() => {
        //     filterData(item);
        // }, 100);
    };


    const filterData = (filter) => {
        if (filter === 'All'  ) {
            setFilteredData(data?.list); // Show all data
        } else {
            setFilteredData(data?.list?.filter((item) => item?.media_cat === filter));
        }
    };


    // useEffect(()=>{
    //     setFilteredData(data?.list)
    // },[data?.list])

    useEffect(() => {
        setActiveItem("All")
        if (data && data.list) {
            setFilteredData(data.list);
        }
    }, [data]);



    let [image, setImage] = useState(false);

    let handelImage = (image) => {
        setImage(image);
    };
    const PhotoItem = ({image, thumb, group, data, category, title, link, date}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <Media img={image} category={category} title={title} date={date} photoGallery/>

            {/*<img alt='' src={image} style={{width: "100%"}}/>*/}

        </LightgalleryItem>

    );
    const PhotoItem2 = ({image, thumb, group}) => (
        <div style={{maxWidth: "250px", width: "200px", padding: "5px"}}>
            <LightgalleryItem group={group} src={image} thumb={thumb}>
                <img src={image} style={{width: "100%"}}/>
            </LightgalleryItem>
        </div>
    );



    return (
        <StyledComponent className={'media-list pt-80 pb-200'}>
            <Container>
                <div className="media-list__top">
                    <Row>
                        <Col md={12} sm={12}>
                            <ul>
                                <li
                                    className={activeItem === 'All' ? 'active' : ''}
                                    onClick={() => handleItemClick('All')}
                                >
                                    All
                                </li>

                                {
                                    data?.filter?.media_category && data?.filter?.media_category?.length > 0 &&
                                    data?.filter?.media_category?.map((e,i)=>{
                                    return(
                                        <li
                                            key={i}
                                            className={`${activeItem === e.label || (activeItem === 'All' && e.label === 'All') ? 'active' : ''}`}
                                            onClick={() => handleItemClick(e.label)}
                                        >
                                            {e.label}
                                        </li>

                                    );
                                })
                                }
                            </ul>
                        </Col>
                    </Row>
                </div>
                <Row>
                    {filteredData?.length > 0 && filteredData?.map(i => (
                        i?.media_cat === 'Gallery' ?
                            <LightgalleryProvider
                                lightgallerySettings={
                                    {
                                        download: false,
                                        fullScreen: true,
                                        share: false
                                    }
                                }
                            >

                                <Col xl={4} md={6} sm={6} key={i?.id}>
                                    <PhotoItem key={i?.id} image={i?.media_thumb}
                                               category={i?.media_cat} title={i?.post_title} date={i?.media_date}
                                               group={`group${i?.gallery?.[0]?.id}`}/>
                                    <div
                                        style={{
                                            display: "none",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        {i?.gallery ? i?.gallery?.slice(1)?.map((element, k) => (
                                            <PhotoItem2 key={k} image={element?.image}
                                                        group={`group${element?.id}`}/>
                                        )) : ''}
                                    </div>
                                </Col>

                            </LightgalleryProvider>
                            :
                            <>
                                <Col key={i?.id} xl={4} md={6} sm={6}>
                                    <Media category={i?.media_cat} link={i?.post_name}
                                           date={i?.media_date}
                                           title={i?.post_title}
                                           img={i?.media_thumb}
                                    />
                                </Col>
                            </>

                    ))}
                </Row>

            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F7F3F0;
  .media-list__top {
    width: 100%;
    margin-bottom: 60px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        padding: 0px 24px;
        border: 1px solid #004185;
        border-radius: 100px;
        height: 33px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        transition: .5s ease;
        cursor: pointer;
        color: #004185;

        &:not(:nth-last-child(1)) {
          margin-right: 30px;
        }

        &:hover, &.active {
          background-color: #004185;
          color: #ffffff;
        }
      }
    }
  }

  .search {
    position: relative;

    input {
      width: 100%;
      border-radius: 100px;
      height: 33px;
      border: 1px solid ${text};
      box-shadow: none;
      outline: none;
      padding-left: 24px;
      padding-right: 60px;
      position: relative;

      &::placeholder {
        color: ${text};
      }
    }

    button {
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: transparent;
      box-shadow: none;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
    }

  }

  .media-single {
    margin-bottom: 30px;
  }

  @media (max-width: 767px) {
    .media-list__top ul {
      margin-bottom: 30px;
        li{
            margin-bottom: 15px !important;
        }
    }

    .media-single {
      margin-bottom: 40px;
    }
  }
`;

export default MyComponent;
