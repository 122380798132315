import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
const MyComponent = ({data}) => {

    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetRight)

    }, [])
    return (
        <StyledComponent className={`counter`} offset={offset} id={'mission'}>
            <Container fluid className={'bestproduct'}>
                <Row>
                    <Col md={{span:0 , offset:1}} className={'bestproduct__info'}>
                        {/*<img src={bg_img}/>*/}
                        <div className={'bestproduct__info__data'}>
                            <Col md={6} className={'bestproduct__info__data__left'}>
                                <h3 className={'split-up'}>{reactHtmlParser(data?.title)}</h3>
                            </Col>
                            <Col md={6} className={'bestproduct__info__data__right'}>
                                <ul>
                                    {
                                        data?.mission_vision && data?.mission_vision?.length>0 &&
                                        data?.mission_vision?.map((e,i)=>{
                                            return(
                                                <li key={i}>
                                                    <p className={'split-up'}>{e?.title}</p>
                                                    <h5 className={'split-up'}>{reactHtmlParser(e?.description)}</h5>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  &:after{
    height: calc(50vh - 40px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #F7F3F0;
    content: "";
    z-index: -1;
  }
  .bestproduct{
    &__info{
      background-color: #0C286E;
      position: relative;
      padding: 160px 70px 100px;
      padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '180px'};
      img{
        position: absolute;
        height: 535px;
        width: 305px;
        transform: translateX(10%);
        background-position: center;
        object-fit: contain;
        opacity: 0.5;
      }
      &__data{
        display: flex;
        &__left{
          h3{
            font-weight: 500;
            color: #FAFAFA;
          }
        }
        &__right{
          ul{
            li{
              position: relative;
              counter-increment: count;
              padding: 0 0 0 86px;
              border-bottom: 1px solid #C4C4C4;
              margin: 0 0 80px;
              flex: 0 0 calc(50% - 15px);
              &:before{
                content: counter(count,Numeric);
                position: absolute;
                padding: 0;
                color: #F9F9F9;
                top: 0px;
                left: 0px;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                font-size: 60px;
                font-weight: 500;
                line-height: 60px;
              }
              &:last-child {
                border-bottom: none;
              }
              p{
                font-weight: 500;
                color: #FAFAFA;
                margin-bottom: 20px;
                
              }
              h5{
                font-weight: 500;
                color: #FAFAFA;
                margin-bottom: 60px;
              }
            }
          }
        }
      }
      @media (max-width: 991px){
        padding: 150px 30px 100px;
      }
    }
    @media(max-width: 767px){
      overflow: hidden;
      .bestproduct{
        &__info{
          padding: 100px 0;
          img{
            transform: translateX(35%);
            height: 100%;
            object-fit: contain;
            background-position: center;
          }
          &__data{
            display: initial;
            &__left{
              margin-bottom: 40px;
              .title{
                max-width: 100%;
              }
            }
            &__right{
              ul{
                li{
                  padding: 0;
                  &:before{
                    position: unset;
                    display: block;
                    padding-bottom: 20px;
                    font-size: 40px;
                    line-height: 48px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MyComponent;
