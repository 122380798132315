import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {hover} from "../../styles/globalStyleVars";
import {BsPlus} from 'react-icons/bs';
import {Link} from "react-router-dom";
import {Img} from "../Img";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import IconButton from "../IconButton";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import reactHtmlParser from "react-html-parser";
gsap.registerPlugin(ScrollTrigger);


const AboutSection = ({countDown, hoverBox, bg, pb, mission, bgc, data}) => {
    const [winWidth, setWinWidth] = useState(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => {
            setWinWidth(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    //gsap animation
    useEffect(() => {
        const boxes = document.querySelectorAll('.about-section__bottom__single');
        {
            if(winWidth){
                boxes.forEach((box, index) => {
                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: box,
                            start: "clamp(top center)", // Adjust the starting position
                            end: "clamp(bottom center)", // Adjust the ending position
                            scrub: true,
                        }
                    });

                    if (index === 0 || 3){
                        tl.to(box,{
                            y: -20,
                            duration: 0.2,
                            ease: 'linear',
                        });
                    }

                    if (index === 1) {
                        // Apply parallax effect to the second box (index 1)
                        tl.to(box, {
                            y: -50, // Adjust the parallax effect here
                            duration: 1,
                            ease: 'linear',
                        });
                    } else if (index === 2) {
                        // Apply parallax effect to the third box (index 2)
                        tl.to(box, {
                            y: -35, // Adjust the parallax effect here
                            duration: 0.5,
                            ease: 'linear',
                        });
                    }
                });
            }
        }
    }, [winWidth, data]);




    return (
        <StyledAboutSection bgc={bgc} className={`about-section pb-200`}>
            <Container fluid className='about-section__bottom'>
                <Row>
                    {
                        data && data?.length > 0 &&
                        data?.map((e, i) => {
                            // Calculate 80% of e?.number and round it to the nearest integer
                            const startValue = Math.round(e?.number * 0.9);

                            console.table('start', startValue);
                            return (
                                <Col sm={3} className='about-section__bottom__single p-0' key={i}>
                                    <div className="about-section__bottom__single__inner">
                                        <CountUp
                                            start={startValue} // Set the start value dynamically
                                            end={e?.number}
                                            duration={2}
                                            separator=","
                                            decimals={''}
                                            decimal=","
                                            suffix={'+'}
                                        >
                                            {({ countUpRef, start }) => (
                                                <VisibilitySensor onChange={start} partialVisibility={{ top: 0, bottom: 20 }} delayedCall>
                                                    <h2 ref={countUpRef}></h2>
                                                </VisibilitySensor>
                                            )}
                                        </CountUp>
                                        <p className={'split-up'}>{reactHtmlParser(e?.title)}</p>
                                    </div>
                                </Col>
                            );
                        })
                    }

                </Row>
            </Container>
        </StyledAboutSection>
    );
};

const StyledAboutSection = styled.section`
  position: relative;
  z-index: 9999;
  margin-top: -50px;
  //transform: translateY(-10%);
 .about-section{
   &__bottom{
     &__single{
       background-color: #3CB649;
       height: 341px;
       width: 341px;
       padding: 40px !important;
       &:nth-child(2){
         background-color: #004185;
         margin-top: 30px !important;
         @media(max-width: 767px){
           margin-top: 0 !important;
         }
       }
       &:nth-child(3){
         background-color: #25292C;
         margin-top: 15px !important;
         border-right: 1px solid #fff;
         @media(max-width: 767px){
           border-right: 0;
           border-bottom: 1px solid #fff;
           margin-top: 0 !important;
         }
       }
       &:nth-child(4){
         background-color: #25292C;
         margin-top: 0px !important;
       }
       &__inner{
         height: 100%;
         display: flex;
         -webkit-box-pack: justify;
         justify-content: space-around;
         flex-direction: column;
         h2{
           color: #FCFEFF;
         }
         p{
           color: #FCFEFF;
         }
       }
     }
   }
   @media(max-width: 767px){
     &__bottom{
       &__single{
         width: 100%;
       }
     }
   }
 }
  
`;
export default React.memo(AboutSection);
