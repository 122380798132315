import React, {useState} from 'react';
import styled from 'styled-components';
import {body_font, hover, medium, secondary_wood, text} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import {Img} from "./Img";
import {Link} from "react-router-dom";
import reactHtmlParser from "react-html-parser";
import PeoplePopup from "./PeoplePopup";

const ConcernBox = ({img, title, link, des, logo, md, data}) => {

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
    }

    return (

        <StyledConcernBox className={'team-box'} md={md}>
            <PeoplePopup show={show} data={popupId} handleClose={handleClose}/>
            <div className="wrapper"  >
                {
                    md ?

                        <div>
                            <div  className={'img-wrapper '}>
                                <Img data-speed={1} src={img }/>
                            </div>
                            <div className="information">
                                <p>{title ? title : 'ISABELLA MIREAU'}</p>
                                <p>{des ? des : 'PRINCIPAL ARCHITECH'}</p>
                            </div>
                        </div>

                        :
                        <div className={'pointer '} onClick={() => handleShow(data)}>
                            <div className={'img-wrapper '}>
                                <Img src={img }/>

                            </div>
                            <div className="information">
                                <p>{title ? title : 'ISABELLA MIREAU'}</p>
                                <p>{des ? des : 'PRINCIPAL ARCHITECH'}</p>
                            </div>
                        </div>
                }

            </div>
        </StyledConcernBox>

    )
};


const StyledConcernBox = styled.div`
  .wrapper {
    position: relative;

    .img-wrapper {
      padding-top: ${props => props.md ? 'calc(480 / 370 * 100%)' : 'calc(330 / 270 * 100%)'};
      position: relative;
      overflow: hidden;

      .global-image img {
        transform: ${props => props.md ? 'scale(1)' : 'scale(1.01)'};
        transition: all 0.3s ease-in;
      }

    }

    .information {
      margin-top: 20px;
      text-align: ${props => props.md ? 'left' : 'center'};

      p {
        margin: 0 0 3px;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #25292C;
        &:last-child {
          opacity: 50%;
          font-weight: 400;
          margin: 0;
        }
      }
    }

    a {
      &:hover {
        .img-wrapper {
          .global-image img {
            transform: ${props => props.md ? 'scale(1)' : 'scale(1.015)'};
          }
        }
      }
    }
  }
  .reveal{
    overflow: hidden;
  }
`;

export default React.memo(ConcernBox);














