import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchProjectData} from "../../api/redux/project";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import ProjectLists from "../../components/project/ProjectLists";
import BannerSlider from "../../components/project/BannerSlider";

const MyComponent = () => {

    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECT
        dispath(fetchProjectData([api_url]))
    }, [dispath])


    let getPost = useSelector(store => store.projectReducer)

    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let page_data = data?.page_meta;
    let banner = data?.banner;
    let projects = data?.project_list;


    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title : ''}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                {/*<InnerBanner*/}
                {/*    img={banner?.image}*/}
                {/*    title={banner?.title}*/}
                {/*    subtitle={banner?.desc}*/}
                {/*/>*/}
                <BannerSlider data={projects}/>
                {/*<ProjectSlider data={projects}/>*/}
                <ProjectLists data={projects}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
