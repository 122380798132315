import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import CloseModal from "../components/CloseModal";

const PeoplePopup = ({
                         show,
                         handleClose,
                         no_img,
                         item,
                         title,
                         description,
                         data,
                         subtitle,
                         img
                     }) => {


    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])




    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="gph_modal popup-version-one"
            >
                <div className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>

                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleClose} className="modal-close ">
                                    <CloseModal/>
                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data d-flex">
                                    <Col sm={12} className="modal-main-content p-0">
                                        <div className="profile-info">
                                            <div className="img-wrp">
                                                <img src={'images/dynamic/team.jpg'}/>
                                            </div>

                                            <h5>{reactHtmlParser('Amelia Banks')}</h5>
                                            <p>{'Data Analysts'}</p>

                                        </div>
                                        <div className="profile-details">
                                            <p>Isabella Mireau stands as a visionary force in the realm of architecture, seamlessly weaving
                                                creativity, functionality, and innovation into the fabric of her designs. With a profound
                                                dedication to her craft, Isabella has redefined the art of shaping spaces, earning her the
                                                esteemed position of Principal Architect.</p>

                                            <p>A graduate of the prestigious [Name of Architecture School], Isabella's journey began with a
                                                fascination for the intricate dance between form and function. Her architectural prowess is
                                                exemplified by an extensive portfolio of iconic structures that have graced urban landscapes
                                                and enriched communities across the globe.</p>

                                             <p>Isabella's design philosophy goes beyond aesthetics; it delves into the very essence of human
                                                 experience. Her commitment to sustainable practices has led her to spearhead projects that
                                                 harmoniously coexist with their environments, leaving a minimal footprint while maximizing utility.
                                             </p>
                                        </div>
                                    </Col>


                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }

  h4 {
    letter-spacing: 1.5px;
  }

`;


export default React.memo(PeoplePopup);