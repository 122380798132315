import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import homeReducer, {fetchHomeData} from "../../api/redux/home";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import Statistics from "../../components/home/Statistics";
import Overview from "../../components/home/Overview";
import IconButton from "../../components/IconButton";
import Video from "../../components/home/Video";
import FeaturedProject from "../../components/home/FeaturedProject";
import KeyFeatures from "../../components/home/KeyFeatures";
import OurProject from "../../components/home/OurProject";
import Associates from "../../components/home/Associates";
import HomeBanner from "../../components/home/HomeBanner";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useLocation} from "react-router-dom";
import {gsap} from "gsap";

const Home = () => {

    const dispath = useDispatch()
    const location = useLocation()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.HOME
        dispath(fetchHomeData([api_url]))
    }, [dispath])


    let getPost = useSelector(store => store.homeReducer)


    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let page_data = data?.page_meta;
    let banner = data?.home_slider?.slider;
    let stat =  data?.home_counter?.counter;
    let about = data?.home_about;
    let project = data?.feature_project;
    let product = data?.feature_product;
    let associates = data?.associates;
    let video = data?.home_video;
    let keyFeature = data?.key_features;


    //scroll to section
    useEffect(() => {
        if (location.hash && !getPost?.loading) {
            const targetElement = document.querySelector(location.hash);
            if (targetElement) {
                const topPx = targetElement.offsetTop + 100;
                if (topPx >= 0 && topPx <= document.documentElement.scrollHeight) {
                    gsap.to(window, {
                        duration: 0.8,
                        scrollTo: topPx
                    });
                } else {
                    console.error("Invalid target offset for scrolling:", topPx);
                }
            } else {
                console.error("Target element not found:", location.hash);
            }
        }
    }, [location.hash, getPost]);



    // console.log('hash', location.hash)


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title : ''}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>
            <StyledSection>
                <HomeBanner data={banner}/>
                <Statistics data={stat}/>
                <Overview bg data={about}/>
                <Video data={video}/>
                <FeaturedProject data={product}/>
                <KeyFeatures data={keyFeature}/>
                <OurProject data={project}/>
                <Associates id={'concern'} data={associates}/>
            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
