import React, {useState, useEffect} from 'react';
import {gradient, hover, hoverNd, title} from '../styles/globalStyleVars';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";


const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    hoverColor,
                    target,
                    borderColor,
                    iconbg,
                    iconcolor,
                    margintop,
                    marginbottom,
                    iconHover
                }) => {

    return (
        <StyledBtn className={`dc-btn`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hoverColor={hoverColor}
                   iconcolor={iconcolor}
                   iconbg={iconbg}
                   margintop={margintop}
                   marginbottom={marginbottom}
                   iconHover={iconHover}
        >
            <a>
                    <span>
                        <div className={'bg'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
                                <g id="Group_19898" data-name="Group 19898" transform="translate(-513 -1216)">
                                    <line id="Line_12374" data-name="Line 12374" y2="10" transform="translate(518.5 1216.5)"
                                          fill="none" stroke="#002856" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_12375" data-name="Line 12375" y2="10"
                                          transform="translate(523.5 1221.5) rotate(90)" fill="none" stroke="#002856"
                                          stroke-linecap="round" stroke-width="1"/>
                                </g>
                            </svg>
                            <p>{ReactHtmlParser(text) || 'explore'} </p>
                        </div>
                    </span>
            </a>
        </StyledBtn>
    );
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin-top: ${props => props.margintop || '0'};
    margin-bottom: ${props => props.marginbottom || '0'};
    width: max-content;
    //height: 100%;
    cursor: pointer;

    a {
      span {
        color: ${props => props.color || '#FCFEFF'};
        display: flex;
        align-items: center; /* Align vertically */
        flex-basis: 50%;
        height: 52px;
        width: 100%;
        .bg {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: ${props => props.iconbg || `#f2f2f2`};
          border-radius: 50%;
          //margin-right: 20px;
          transition: 0.5s ease-in-out;
          gap:20px;
        }
        svg {
          position: absolute;
          transition: all 0.3s cubic-bezier(0.4, 0, 0, 1) 0s;
          g{
            line{
              stroke: ${props => props.iconcolor || `#000`};
            }
          }
        }

        p{
          position: absolute;
          left: 60px;
          //position: relative;
          //transition: all 0.7s cubic-bezier(0.4, 0, 0, 1) 0s;
          //margin-left: 60px;
          color: ${props => props.color || '#FCFEFF'};
          white-space: nowrap;
        }
        transition: color .3s ease;
        

        &:hover {
          color: ${props => props.hoverColor || '#3CB649'};
          .bg {
            width: 52px;
            height: 52px;
            padding: 20px;
            transition: 0.5s ease-in-out;
            margin-right: 11px;
            background-color: #3CB649;
            svg {
              cursor: pointer;
              transition: all 0.5s cubic-bezier(0.4, 0, 0, 1) 0s;
              transform: rotate(90deg);
              g{
                line{
                  stroke: ${props => props.iconHover || `#FCFEFF`};
                }
              }
            }
            p{
              color: ${props => props.hoverColor || '#3CB649'};;
            }
          }
          p{
            //transition: all 0.7s cubic-bezier(0.4, 0, 0, 1) 0s;
            //margin-left: 65px;
          }
        }
      }
    }
  }

  @media (max-width: 550px) {
    width: 100% !important;
  }
`;


export default React.memo(Button);
