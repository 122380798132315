import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchProductDataDetail} from "../../api/redux/product";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import DetailsBox from "../../components/product/DetailsBox";
import Advantages from "../../components/product/Advantages";
import ProjectBox from "../../components/project/ProjectBox";
import ProductBox from "../../components/product/ProductBox";
import Button from "../../components/Button";
import IconButton from "../../components/IconButton";
import {text} from "../../styles/globalStyleVars";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.productReducer)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PRODUCT_DETAILS
        dispatch(fetchProductDataDetail([api_url + `/${slug}`]))
    }, [slug])
    //refactor
    let details = getData?.detail?.[0];
    let meta = details?.page_meta;

    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : ''}`}</title>

                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={meta?.og_desc}/>

                }
            </Helmet>

            <StyledComponent >
                <DetailsBox data={details}/>
                {
                    details?.comparison?.title &&
                    <Advantages data={details}/>
                }
                <Container className={'related-products'}>
                    <div className={'top'}>
                        <h3>Related Products</h3>
                        <Button
                            background={'transparent'}
                            borderColor={text}
                            color={text}
                            text={'View All'}
                            src={'/product'}
                        />
                    </div>
                    <Row>
                        {
                            details?.related_products && details?.related_products?.length > 0 &&
                            details?.related_products?.map((e,i)=>{
                                return(
                                    <Col md={3} key={i}>
                                        <ProductBox
                                            title={e?.post_title}
                                            text={e?.size}
                                            img={e?.image}
                                            url={e?.post_name}
                                        />
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </Container>

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
    background-color: #F7F3F0;
    .related-products{
      padding-top: 80px;
      padding-bottom: 80px;
      
      .top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .dc-btn{
          width: max-content;
        }
        margin-bottom: 40px;
        
        @media(max-width: 768px){
          flex-direction: column;
          gap: 20px;
        }
      }

    }
`;

export default MyComponent;
