import React from 'react';
import styled from 'styled-components';
import {GoArrowLeft, GoArrowRight} from "react-icons/go";
import LeftSvg from "./LeftSvg";
import RightSvg from "./RightSvg";

const NavigationIcon = ({next_id, prev_id, color}) => {


    return (

        <StyledNavigationIcon className={'navigation_button'}>
            <ul>
                <li className='hover_left hover' id={next_id ? next_id : 'service-prev'}>
                    <LeftSvg color={'#fff'}/>
                </li>
                <li className='hover_right hover' id={prev_id ? prev_id : 'service-next'}>
                    <RightSvg color={'#fff'}/>
                </li>
            </ul>
        </StyledNavigationIcon>

    )
};


const StyledNavigationIcon = styled.div`
  position: relative;
  z-index: 10;

  ul {
    display: flex;
    gap: 20px;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid rgb(252 254 255 / 50%);
      position: relative;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      svg {
        color: #3C3C3B;
        z-index: 2;

        g {
          transition: 0.7s all ease;
        }
      }
      &.hover:after {
        background-color: #FCFEFF;
      }

      &:hover {
        svg{
          g{
            line{
              stroke: #3CB649;
            }
          }
        }
        #Hover_Prev {
          stroke-dasharray: 180px 200px;
          opacity: 1;
        }

        #Hover_Next {
          stroke-dasharray: 180px 200px;
          opacity: 1;
        }
      }

    }
  }



`;

export default React.memo(NavigationIcon);














