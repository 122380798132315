import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchProductData, fetchProductDataDetail} from "../../api/redux/product";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import ProductLists2 from "../../components/product/ProductLists2";

const MyComponent = () => {

    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    // Update the useEffect to listen to changes in the location object
    useEffect(() => {
        let api_url = apiEndPoints.PRODUCT;
        dispath(fetchProductData([api_url]));
    }, [dispath]);


    let getPost = useSelector(store => store.productReducer)

    let data = getPost?.posts?.[0];
    let page_data = data?.page_meta;
    let banner = data?.banner;
    let product = data?.list;



    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title : ''}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    img={banner?.image}
                    title={banner?.title}
                    subtitle={banner?.desc}
                />
                <ProductLists2
                    product={product}
                />
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
`;

export default MyComponent;
