import React, {useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Row} from "react-bootstrap";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import {BsX} from "react-icons/bs";
import reactHtmlParser from "react-html-parser";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';

const PopupV1 = ({
                     show,
                     handleClose,
                     no_img,
                     item,
                     title,
                     description,
                     data,
                     subtitle,
                     video,direct_video
                 }) => {

    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])



    const [open, setOpen] = useState(true);
    const [videoId, setVideo] = useState('');
    const modalRef = useRef(null);


    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };


    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal"
            >
                <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                    <Modal.Body>

                        <Container>
                            <Row>


                                <div className="modal-data d-flex">
                                    {
                                        direct_video ?

                                            <ModalVideo ref={modalRef} channel='youtube' isOpen={video} videoId={direct_video}  onClose={handleCloseModal}/>

                                            :

                                            <ModalVideo ref={modalRef} channel='youtube' isOpen={video} videoId={data?.data?.short_desc}  onClose={handleCloseModal}

                                            />

                                    }

                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledModal>

    )
};


const StyledModal = styled.div`

  .modal-dialog {
    margin: 0;
  }

  h4 {
    letter-spacing: 1.5px;
  }

`;


export default React.memo(PopupV1);
