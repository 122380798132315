import React from 'react';
import styled from "styled-components";
import {Img} from "../Img";
import {Col, Container, Form, Row} from "react-bootstrap";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";
import {ImgInnerBanner} from "../ImgInnerBanner";
import Button from "../Button";

const InnerBanner = ({img, subtext, title, uptitle,project,notFull, mobImg}) => {

    // Define the images for different viewports
    const largeImage = img?.large;
    const mediumImage = mobImg ? mobImg : img?.medium;
    const smallImage = mobImg ? mobImg : img?.thumbnail;

    // Determine the viewport size and set the image accordingly
    let imgUrl;
    if (window.innerWidth >= 1366) {
        imgUrl = largeImage;
    } else if (window.innerWidth >= 768) {
        imgUrl = mediumImage;
    } else {
        imgUrl = smallImage;
    }

    return (
        <StyledInnerBanner notFull={notFull} project={project} className='InnerBanner '>
            <ImgInnerBanner src={imgUrl ? imgUrl : ''}/>
            <Container>
                <Row>
                    <Col md={12}>
                        <div>
                           <div className={'order'}>
                               <div className={'order__tracking'}>
                                   <div className={'title'}>
                                       <h3>{title ? reactHtmlParser(title) : ''}</h3>
                                   </div>
                                   <Form className={``}>
                                       <Form.Group className="form-group" controlId="formBasicEmail">
                                           <Form.Control  className={'form-control-lg'}
                                                          type="number"
                                                          placeholder="Enter Your Tracking Number"/>
                                           <div className="dc-btn-submit">
                                               <Button  width={'100'}  text={'Track'} color={'#3C3C3B'}
                                                        background={'#ED1B34'}/>
                                           </div>
                                       </Form.Group>
                                   </Form>
                               </div>
                           </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: ${props => props.notFull ? '75vh' : '100vh'} ;
  position: relative;
  background-color: #DDD;
  height:${props => props.notFull ? '75vh' : '100vh'};
  overflow: hidden;

  &:after {
    //content: '';
    position: absolute;
    background: linear-gradient(142deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5018601190476191) 0%);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    height: 100%;
    margin: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    //text-align: center;
    z-index: 2;

    h3 {
      margin-bottom: ${props => props.project ? '30px' : '15px'} ;
    }

    h3{
      color: #F1EEE9;
    }

    .up-title {
      margin-bottom: 35px;
    }

    .row {
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  
  .order{
    display: flex;
    flex-direction: column;
    align-items: center;
    &__tracking{
      width: 65%;
      @media(max-width: 767px){
        width: 100%;
      }
    }
  }
  
  //form{
  //  display: flex;
  //  justify-content: center;
  //}
  .form-group {
    position: relative;
    //width: 65%;
    text-align: center;

    .form-control {
      height: 45px;
      //padding-left: 40px;
      background-color: transparent;
      border-radius: 40px;
      font-size: 16px;
      font-weight: 500;
      padding-left: 30px;
      color: #FFFFFF;
      autocomplete: off;
      ::placeholder {
        color: rgba(255, 255, 255, 0.5);
        font-size: 16px;
        font-weight: 500;
        outline: none;
        border: none;
        @media(max-width: 767px){
          font-size: 14px;
        }
      }
      :focus{
        outline: none;
        outline-color: transparent;
        border-color: white;
      }
    }

    .dc-btn-submit {
      position: absolute;
      top: 0;
      right: 0;

      .dc-btn {
        height: 45px;
      }

      a {
        display: flex;
        height: 100%;
        width: 100%;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        margin: 0px;
        line-height: 18px;
        color: #3C3C3B;
        text-transform: capitalize;
        border: none;
        background-color: #FFFFFF;
        letter-spacing: 0px;
        position: relative;
        border-radius: 30px;
        overflow: hidden;
        span{
          //color: #3C3C3B;
        }
      }
    }
  }
  @media (max-width: 376px) {
    padding-top: calc(812 / 375 * 100%);
    h3{
      font-size: 36px;
      line-height: 48px;
    }
  }

`;

export default InnerBanner;
