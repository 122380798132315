import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";

const MyComponent = ({img, title, date, category, link, photoGallery}) => {
    const dateObject = new Date(date);
    const month = dateObject.toLocaleString('default', { month: 'long' });
    const year = dateObject.getFullYear();
    const formattedDate = `${month} ${year}`;
    return (
        <StyledComponent className={'media-single '}>
            {link ? <Link to={`/media/${link}`}/> : ''}
            <div className="media-single__img">
                <Img src={img}/>
            </div>
            <div className="media-single__content">
                <h4>{title}</h4>
                <div className={'bottom'}>
                    <span>
                        <p className={'day'}>{new Date(date).getDate()}</p>
                        <p>{formattedDate}</p>
                    </span>
                    <span>{category}</span>
                </div>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;

  a {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
  }

  .media-single__img {
    padding-top: calc(520 / 370 * 100%);
    position: relative;
    overflow: hidden;
  }
  
  img{
    transform: scale(1.01);
    transition: transform .5s ease-in-out;
  }

  .media-single__content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    h4 {
      color: #FCFEFF;
      //font-size: 24px;
      //line-height: 32px;
      width: 100%;
    }

    p {
      color: #FCFEFF;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .bottom{
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;;
      span{
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: #FCFEFF;
        .day{
          font-size: 60px;
          line-height: 68px;
          font-weight: 300;
          color: #FCFEFF;
        }
        p{
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: #FCFEFF;
        }
      }
    }
  }

  &:hover {
    img {
      transform: scale(1.04);
      transition: transform .5s ease-in-out;
    }
  }

  @media (max-width: 767px) {
    .media-single__content {
      padding: 20px;

      h4 {
        //font-size: 20px;
        //line-height: 26px;
      }

    }
  }
`;

export default MyComponent;
