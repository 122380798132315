import React from 'react';
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

const MyComponent = ({ color, hover }) => {
    return (
        <StyledComponent color={color} hover={hover} className={'prev'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828" viewBox="0 0 7.828 12.828">
                <g id="Group_6" data-name="Group 6" transform="translate(1.414 11.414) rotate(-90)">
                    <line id="Line_4" data-name="Line 4" y1="5" x2="5" fill="none" stroke={color} stroke-linecap="round" stroke-width="1" />
                    <line id="Line_5" data-name="Line 5" x1="5" y1="5" transform="translate(5)" fill="none" stroke={color} stroke-linecap="round" stroke-width="1" />
                </g>
            </svg>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  display: flex;

  svg {
    g {
      line {
        stroke: ${props => props.color || '#FCFEFF'};
      }
    }
  }
`;

export default React.memo(MyComponent);
