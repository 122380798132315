import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const OverviewInner = ({data}) => {

    return (
        <StyledOverviewInner className={`about_overview pt-200`}>
            <Container>
                <Row>
                    <Col md={{span:9}}>
                        <Title
                            margin={'0 0 60px'}
                            text={data?.title}
                            color={'#FCFEFF'}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <div className="img_wrapper">
                            <Img src={data?.image}/>
                        </div>
                    </Col>
                    <Col md={{span: 6, offset: 1}}>
                        <div className="contents">
                            <p>{reactHtmlParser(data?.desc)}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #004185;
  
  
  .img_wrapper {
    padding-top: calc(391 / 500  * 100%);
    position: relative;
  }

  .contents {
    p {
      color: #FCFEFF;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .short-description {
      margin-top: 46px;
      width: 70%;

      h4 {
        margin-bottom: 40px;
        font-size: 15px;
        font-weight: 300;
        line-height: 22px;
        letter-spacing: 0.7px;
      }

    }

  }

  @media (max-width: 767px) {
    padding-bottom: 100px;
    .title {
      margin-bottom: 60px;
    }

    .img_wrapper {
      margin-bottom: 40px;
    }

    .contents {
      margin-top: 40px;
      .short-description {
        width: 100%;
      }

    }
  }

`;


export default React.memo(OverviewInner);
