import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import TeamBox from "../TeamBox";
import reactHtmlParser from "react-html-parser";

const ManagingDirectors = ({data}) => {


    return (<StyledComponent className={'md-message pb-200'}>
            <Container>
                <Row>
                    <Col md={4}>
                        {window?.innerWidth < 767 && <h3 className={'mobile-title'}>
                            {data?.title ? reactHtmlParser(data?.title) : ''}
                        </h3>

                        }
                        <div className="box-up">
                            <TeamBox md img={data?.image ? data?.image : ''} title={data?.name} des={data?.designation} />
                        </div>
                    </Col>
                    <Col md={{span: 6, offset: 2}}>
                        <div className="contents">
                            {window?.innerWidth > 767 &&
                                <h3 className="split-up">{data?.title ? reactHtmlParser(data?.title) : ''}</h3>
                            }
                            {reactHtmlParser(data?.message)}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>);
};

const StyledComponent = styled.section`
  background: #F7F3F0;

  .contents {
    h3 {
      margin-bottom: 60px;
      color: #25292C;
    }

    p {
      margin-bottom: 20px;
      color: #25292C;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .mobile-title {
      margin-bottom: 45px;
    }

    .team-box {
      margin-bottom: 60px;
    }
  }
`;

export default React.memo(ManagingDirectors);
