import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {Col, Container, Row} from "react-bootstrap";
import Form from "../../components/contact/Form";
import Accordion from "../../components/faq/accordion";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchFaqData} from "../../api/redux/faq";


const MyComponent = () => {

    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.FAQ
        dispath(fetchFaqData([api_url]))
    }, [dispath])


    let getPost = useSelector(store => store.faqReducer)

    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let page_data = data?.page_meta;
    let banner = data?.banner;
    let faq = data?.faq_list?.list;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title : ''}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    img={banner?.image}
                    title={banner?.title}
                    subtitle={banner?.description}
                />
            </StyledComponent>
            <Accordion data={faq}/>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

  .contact-section-address {
    background: #F7F3F0;

    .row {
      border-bottom: 1px solid rgba(37, 41, 44, 0.2);
      padding-bottom: 30px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    .d-flex {
      justify-content: center;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #3CB649;
      letter-spacing: 0;
      margin: 0;
    }

    a {
      color: #25292C;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      display: block;
      letter-spacing: 0;
    }
  }


  @media (max-width: 767px) {
    .contact-section-address {
      backgrxound: #F1EEE9;

      .row {

      }

      .d-flex {
        justify-content: flex-start;
        svg{
          margin-bottom: 10px;
        }
      }
      p{
        margin-bottom: 20px;
      }


    }

  }
`;

export default MyComponent;
