import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import {Col, Container, Row} from "react-bootstrap";
import Form from "../../components/contact/Form";
import Accordion from "../../components/faq/accordion";
import Title from "../../components/Title";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchRefundData} from "../../api/redux/refund";
import reactHtmlParser from "react-html-parser";


const MyComponent = () => {

    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.REFUND
        dispath(fetchRefundData([api_url]))
    }, [dispath])


    let getPost = useSelector(store => store.refundReducrer)

    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let page_data = data?.page_meta;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title : ''}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent className={'pb-80'}>
                <Container>
                    <Row>
                        <Col>
                            <Title text={data?.refund_policy?.title} color={'#25292C'}/>
                            <div className={'desc split-up'}>
                                {reactHtmlParser(data?.refund_policy?.desc)}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  padding-top: 185px;
  background: #F7F3F0;
  .desc{
    margin-top: 40px;
    p{
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #25292C;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    .split-child{
      margin-bottom: 20px;
    }
  }
`;

export default MyComponent;
