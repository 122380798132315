import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import Title from "../Title";
import reactHtmlParser from "react-html-parser";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import formReducer, {contactForm} from "../../api/redux/form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import IconButtons from "../IconButtons";


const MyComponent = ({data}) => {

    //Form Validation & Submit
    const dispatch = useDispatch();
    const responseData = useSelector(state => state.formReducer);

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };


    const onSubmit = (e) => {
       console.log('mh', e)
        let api_services = apiEndPoints.CONTACT_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-number', e?.phone);
        formData.append('your-message', e?.message);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(contactForm([api_services, formData]));
            reset();
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error?.message)
        }
        if (responseData && responseData?.success !== '') {
            success(responseData?.success)

        }
        reset();

    }, [responseData])




    return (
        <StyledComponent className={'contact-form pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col sm={12} md={{span: 8, offset: 2}}>
                        <Title margin={'0 0 60px 0'} color={'#FFF'} center text={reactHtmlParser(data?.title)}/>
                        <Form onSubmit={handleSubmit(onSubmit, onError)}>
                            <Form.Group className={'from-group'}>
                                <Form.Control  className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                               {...register("name", {
                                                   required: 'Name is required',
                                               })}
                                               type="text" placeholder="Name*"/>
                            </Form.Group>
                            <Form.Group className={'from-group d-flex'}>
                                <Form.Control
                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("phone", {
                                        required: {
                                            value: true, message: 'please enter your phone first'
                                        }, pattern: {
                                            value: /^01[0-9]{9}$/,
                                            message: 'please enter a valid 11 digit phone number'
                                        }
                                    })}

                                    type="number" placeholder="Number*"/>
                                <Form.Control
                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("email", {
                                        required: {
                                            value: true, message: 'please enter your email'
                                        }, pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'please enter a valid email address'
                                        }
                                    })}

                                    type="email" placeholder="Email*"/>
                            </Form.Group>
                            <textarea
                                className={formState?.errors?.email?.message ? ' form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
                                {...register('message', {
                                    // required: {
                                    //     // value:true,
                                    //     message: 'please enter your Message',
                                    // },
                                })}
                                placeholder={'Message'}/>
                            <div className="button" onClick={handleSubmit(onSubmit, onError)}>
                                <IconButtons
                                    text={'Submit'}
                                />
                            </div>

                        </Form>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #004185;

  .from-group {
    margin-bottom: 30px;
    gap: 30px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }


  .d-flex{
    @media(max-width: 767px){
      flex-direction: column !important;
    }
  }
  

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #F5F5F5;
    padding-left: 0;
    height: 45px;
    //margin-bottom: 25px;
    color: #F5F5F5;

    &::placeholder {
      font-size: 15px;
      color: #FCFEFF;
    }
  }
  

  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #F5F5F5;
    height: 80px;
    max-height: 80px;
    min-height: 80px;
    color: #FCFEFF;
    box-shadow: none;
    outline: none;

    &::placeholder {
      font-size: 15px;
      color: #FCFEFF;
    }
  }

   
  
  .button{
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

`;

export default React.memo(MyComponent);
