export const apiEndPoints = {
    PAGE_DATA:'page-data',
    POSTS: 'posts',
    HOME: 'page/home',
    ABOUT: 'page/who-we-are',
    CLIENT: 'page/our-clients',
    CONTACT: 'page/contact',
    CAREER: 'page/career',
    EDUCATE: 'page/how-to-educate',
    REFUND: 'page/refund-policy',
    FAQ: 'page/faq',
    COST: 'page/cost-reduction-chart',
    TRACKING: 'page/order-tracking',
    MEDIA: 'page/media-center',
    MEDIA_DETAILS: 'media',
    PRODUCT: 'page/products',
    PRODUCT_DETAILS: 'product',
    PROJECT: 'page/projects',
    PROJECT_DETAILS: 'project',
    MENU_FOOTER : 'custom/menu-footer',
    CONTACT_FORM: 'contact-form-7/v1/contact-forms/338/feedback',
    CAREER_FORM: 'contact-form-7/v1/contact-forms/339/feedback',
    GLOBAL_SETTING: 'page/footer',
};
