import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Modal from "react-bootstrap/Modal";
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from '../components/Button';
import {gsap, TimelineLite} from "gsap";
import reactHtmlParser from "react-html-parser";
import CloseModal from "./CloseModal";
import CloseButton from "./CloseButton";
import IconButton from "./IconButton";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {contactForm} from "../api/redux/form";


const PeoplePopup = ({
                         show,
                         handleClose,
                         no_img,
                         item,
                         title,
                         description,
                         data,
                         subtitle,
                         img
                     }) => {


    const popupData = data;
    const [winWidth, setWinWidth] = useState(true)
    useEffect(() => {
        if (window.innerWidth > 767) {
            setWinWidth(true)
        } else {
            setWinWidth(false)
        }
        window.addEventListener("resize", () => {
            if (window.innerWidth > 767) {
                setWinWidth(true)
            } else {
                setWinWidth(false)
            }
        });
    }, [])


    // all from popup
    const dispatch = useDispatch()
    const formData = '';
    const [validPosition, setValidPosition] = useState(false)
    const [uploadText, setUploadText] = useState('Attach CV')

    const DropdownOption = [
        {value: 'Front-end Developer', label: 'Front-end Developer'},
        {value: 'WordPress Developer', label: 'WordPress Developer'},
        {value: 'DevOps Developer', label: 'DevOps Developer'},

    ];


    const [cv, setCv] = useState(null)
    const cvRef = useRef()


    // handleUpload function
    function handleUpload(event) {
        const selectedFile = event.target.files[0];

        setCv(event.target.files[0]);

        if (selectedFile) {
            const fileName = selectedFile.name;
            setUploadText(fileName)
            document.querySelector('.gph_upload').classList.add('hide')

        }

    }




    //Form Validation & Submit
    const responseData = useSelector(state => state.formReducer);

    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };


    const onSubmit = (e) => {

        let api_services = apiEndPoints.CAREER_FORM;

        var formData = new FormData();
        formData.append('your-name', e?.name);
        formData.append('your-email', e?.email);
        formData.append('your-number', e?.phone);
        formData.append('your-message', e?.message);
        formData.append('file-971', cv);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(contactForm([api_services, formData]));
            // success('Successfully Submitted')
            reset();
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };
    useEffect(() => {
        if (responseData && responseData?.error !== '') {
            error(responseData?.error?.message)
        }
        if (responseData && responseData?.success !== '') {
            success(responseData?.success)

        }
        reset();

    }, [responseData])





    return (

        <StyledModal>
            <Modal
                show={show}
                item={item}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="popup-version-one CareerModal"
            >
                <div className="noise"></div>

                <div className="main_scroll" style={{height: '100vh'}}>
                    <Modal.Body>

                        <Container>
                            <Row className={'for-close'} onClick={handleClose}>
                                <CloseButton/>
                            </Row>
                        </Container>
                        <Container>
                            <Row>
                                <div className="modal-data modal-content-left d-flex">
                                    <Col md={7} sm={12} xs={12} className="left-content">
                                        <h5>{reactHtmlParser(data?.post_title)}</h5>

                                        <div className="inner-content">
                                            {
                                                reactHtmlParser(data?.job_desc)
                                            }

                                        </div>
                                    </Col>
                                    <Col md={{span: 4, offset: 1}} sm={12} xs={12} className={'right-content'}>
                                        <div className="form-wrapper-all">
                                            <Form method="post"
                                                  enctype="multipart/form-data" onSubmit={handleSubmit(onSubmit, onError)}>
                                                <Row>
                                                    <input name={'spam_protector'} type='hidden'/>
                                                    <input name={'form_id'} value={'career-form'} type='hidden'/>

                                                    <Form.Group className="col-md-12 pb-30">

                                                        <Form.Control
                                                            className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                            {...register("name", {
                                                                required: 'Name is required',
                                                                pattern: {
                                                                    message: 'Name must contain only letters',
                                                                },

                                                            })}

                                                            type="text"
                                                            placeholder="Name*"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="col-md-12 pb-30">
                                                        <Form.Control
                                                            className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                            {...register("email", {
                                                                required: {
                                                                    value: true,
                                                                    message: 'please enter your email'
                                                                },
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                    message: 'please enter a valid email address'
                                                                }
                                                            })}

                                                            type="email"
                                                            placeholder="Email*"/>
                                                    </Form.Group>
                                                    <Form.Group className="col-md-12 pb-30">
                                                        <Form.Control
                                                            className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                            {...register("phone", {
                                                                required: {
                                                                    value: true,
                                                                    message: 'please enter your phone first'
                                                                },
                                                                pattern: {
                                                                    value: /^01[0-9]{9}$/,
                                                                    message: 'please enter a valid 11 digit phone number'
                                                                }
                                                            })}

                                                            type="number"
                                                            placeholder="Phone Number*"/>
                                                    </Form.Group>
                                                    <div className="clear"></div>


                                                    <Form.Group className="col-md-12 pb-30">
                                                        <textarea
                                                            className={formState?.errors?.email?.message ? ' form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
                                                            {...register('message', {
                                                                // required: {
                                                                //     // value:true,
                                                                //     message: 'please enter your Message',
                                                                // },
                                                            })}

                                                            type="text"
                                                            placeholder="Cover Letter"/>

                                                    </Form.Group>
                                                    <Form.Group className="col-md-12 pb-30">
                                                        <Form.Control
                                                            ref={cvRef}
                                                            onInput={handleUpload}
                                                            className={'gph_upload'}
                                                            text={uploadText}
                                                            type="file"
                                                            accept=".pdf"
                                                            {...register('file-971')}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                                <div className={`form-group width-fit`}>
                                                    <div onClick={handleSubmit(onSubmit, onError)}>

                                                        <IconButton
                                                            text={'Submit Application'}
                                                            color={'#FCFEFF'}
                                                            hoverColor={'#3CB649'}
                                                        />

                                                    </div>

                                                </div>
                                            </Form>

                                        </div>
                                    </Col>


                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal>
        </StyledModal>

)
};


const StyledModal = styled.div`

.modal-dialog {
    margin: 0;
}

h4 {
  letter-spacing: 1.5px;
}

`;


export default React.memo(PeoplePopup);