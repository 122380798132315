import React, { useState, useMemo, useEffect } from 'react';
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import ProductBox from "./ProductBox";
import Select, { components } from "react-select";
import { useHistory, useLocation } from "react-router-dom";

const MyComponent = ({ product }) => {
    const history = useHistory();
    const location = useLocation();

    // Get URLSearchParams from the current location
    const searchParams = new URLSearchParams(location.search);

    const [selectedType, setSelectedType] = useState(searchParams.get('type') || 'all');
    const [selectedSub, setSelectedSub] = useState(searchParams.get('sub') || null);

    // Get unique product types and add "All" option
    const uniqueTypes = useMemo(() => {
        if (!product || product.length === 0) return [{ value: 'all', label: 'All' }];
        const types = new Set(product.map(item => item?.product_type).filter(Boolean));
        return [{ value: 'all', label: 'All' }, ...Array.from(types).map(type => ({ value: type, label: type }))];
    }, [product]);

    // Get unique sub-types based on selected type
    const uniqueSubTypes = useMemo(() => {
        if (!product || product.length === 0 || selectedType === 'all') return [];
        const filteredProducts = product.filter(item => item?.product_type === selectedType);
        const subTypes = new Set(filteredProducts.map(item => item?.product_sub_type).filter(Boolean));
        return [{ value: 'all', label: 'All' }, ...Array.from(subTypes).map(subType => ({ value: subType, label: subType }))];
    }, [product, selectedType]);

    const handleTypeChange = (selectedOption) => {
        setSelectedType(selectedOption?.value);
        setSelectedSub('all');
        if (selectedOption?.value === 'all') {
            history.push(location.pathname);  // Clear the search parameters completely
        } else {
            history.push(`${location.pathname}?type=${selectedOption?.value}`);  // Update the URL with the type
        }
    };

    const handleSubChange = (selectedOption) => {
        setSelectedSub(selectedOption?.value);
        if (selectedOption?.value === 'all') {
            history.push(`${location.pathname}?type=${selectedType}`);  // Only show the type in the URL when subtype is 'all'
        } else {
            history.push(`${location.pathname}?type=${selectedType}&sub=${selectedOption?.value}`);  // Update the URL with both type and subtype
        }
    };

// Update filtering logic to account for 'all' cases
    const filteredProducts = useMemo(() => {
        if (!product || product.length === 0) return [];

        return product.filter(item =>
            (selectedType === 'all' || item?.product_type === selectedType) &&
            (selectedSub === 'all' || !selectedSub || item?.product_sub_type === selectedSub)
        );
    }, [product, selectedType, selectedSub]);

// Update state when URL params change
    useEffect(() => {
        const typeParam = searchParams.get('type') || 'all';
        const subParam = searchParams.get('sub') || 'all';
        setSelectedType(typeParam);
        setSelectedSub(subParam);
    }, [location.search]);

    // Custom dropdown styles
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderRadius: 0,
            color: state.isSelected ? '#FFF' : '#25292C',
            backgroundColor: state.isSelected ? '#3CB649' : '#ffffff',
            margin: 0,
            cursor: 'pointer',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: '500',
            "&:hover": {
                backgroundColor: `#3CB649`,
                color: '#ffffff',
                cursor: 'pointer'
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'rgba(0,0,0,0.5)',
            backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
            margin: 0,
            zIndex: 9999,
        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : '#FFF',
            borderRadius: 0,
            cursor: 'pointer',
            zIndex: 9999,
        }),
    };

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    {props.selectProps.menuIsOpen ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.732" height="7.003" viewBox="0 0 11.732 7.003">
                            <g id="Group_24047" data-name="Group 24047" transform="translate(18853.867 -10435.998)">
                                <g id="Group_24046" data-name="Group 24046" transform="translate(-18852.863 10442.258) rotate(-93)">
                                    <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none" stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_12381" data-name="Line 12381" y1="5" x2="5" transform="translate(0 5)" fill="none" stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                                </g>
                            </g>
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="11.414" height="6.414" viewBox="0 0 11.414 6.414">
                            <g id="Group_22451" data-name="Group 22451" transform="translate(10.707 0.707) rotate(90)">
                                <line id="Line_12380" data-name="Line 12380" x2="5" y2="5" fill="none" stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_12381" data-name="Line 12381" y1="5" x2="5" transform="translate(0 5)" fill="none" stroke="#08082b" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                    }
                </components.DropdownIndicator>
            )
        );
    };

    return (
        <StyledComponent className={'pb-200'}>
            <Container>
                <Row className={'filter'}>
                    <Col md={4}>
                        <Select
                            components={{ DropdownIndicator }}
                            styles={customStyles}
                            classNamePrefix={'custom'}
                            className='select-here files'
                            placeholder={'All Products'}
                            options={uniqueTypes}
                            value={uniqueTypes.find(option => option.value === selectedType) || uniqueTypes[0]}
                            onChange={handleTypeChange}
                        />
                    </Col>
                    <Col md={4}>
                        <Select
                            components={{ DropdownIndicator }}
                            styles={customStyles}
                            classNamePrefix={'custom'}
                            className='select-here files'
                            placeholder={'Sub-filter'}
                            options={uniqueSubTypes}
                            value={uniqueSubTypes.find(option => option.value === selectedSub) || null}
                            onChange={handleSubChange}
                            isDisabled={!selectedType || selectedType === 'all'}
                        />
                    </Col>
                </Row>
                <Row>
                    {filteredProducts.map((item, index) => (
                        <Col md={4} key={index}>
                            <ProductBox
                                title={item?.post_title}
                                text={item?.product_size}
                                img={item?.product_image}
                                url={item?.post_name}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #F7F3F0;
    padding-top: 80px;

    .filter {
        margin-bottom: 80px;
        @media(max-width: 767px) {
            gap: 20px;
        }
    }

    .custom__control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #25292C !important;
        border-radius: 0;
        box-shadow: none;
        outline: none !important;
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        padding: 0;

        .custom__placeholder, .custom__single-value {
            color: #25292C;
            font-size: 15px;
            line-height: 20px;
        }

        .custom__value-container {
            padding-left: 0;
        }
        .custom__dropdown-indicator {
            padding: 0;
        }
    }
`;

export default MyComponent;