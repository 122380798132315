import styled from "styled-components";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import {BsChevronDown} from "react-icons/bs";
import {Link} from "react-router-dom";
import React from "react";
import Select, {components} from "react-select";
import reactHtmlParser from "react-html-parser";



const Faq = ({data}) => {

    return (
        <StyledFaq className="job-lists pt-120 pb-120">
            <Container>
                <Row>
                    <Col sm={12} className='job-lists__content'>
                        <Accordion defaultActiveKey="0">
                            {
                                data && data?.length>0 &&
                                data?.map((e, i)=>{
                                    return(
                                        <Accordion.Item eventKey={i}>
                                            <Accordion.Header>
                                                {reactHtmlParser(e?.title)}
                                                <span><BsChevronDown/></span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {reactHtmlParser(e?.desc)}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })
                            }
                        </Accordion>
                    </Col>

                </Row>


            </Container>

        </StyledFaq>
    );
};

const StyledFaq = styled.section`
  background-color: #F7F3F0;
  
  .accordion-item {
    margin-bottom: 20px;
  }

  .accordion-header {
    position: relative;
    margin: 0px;
    font-size: unset;
    line-height: unset;
    margin-bottom: 20px;
    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: #3CB649;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      text-transform: capitalize;
      padding-bottom: 20px;
      border-bottom: 1px solid #3CB649;
      width: 100%;
      text-align: left;
      //margin-bottom: 50px;
      transition: all .4s ease;

      &.collapsed {
        color: #1A1818;
        border-color: #1A1818;

        span {
          background-color: black;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: #3CB649 !important;
          }
        }
      }
    }
    button .accordion-button{
      padding-top: 0!important;
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #ffffff;
      background-color: #3CB649;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: #3CB649;
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: #3CB649;
        border-color: #3CB649;
      }
    }
  }

  .accordion-body {
    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      //padding-top: 20px;
      color: #25292C;
    }
    ol{
      margin-top: 20px;
      padding-left: 0;
      li{
        position: relative;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgb(37 41 44 /20%);
        padding-left: 20px;
        &:after{
          height: 10px;
          width: 10px;
          background: #25292C;
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          border-radius: 50%;
        }
        &:last-child{
          margin-bottom: 0;
          border-bottom: unset;
          padding-bottom: 0;
        }
      }
    }
  }
  

  @media (max-width: 767px) {
    .job-lists__sidebar {
      margin-bottom: 50px;

      p {
        width: 100%;
      }
    }
  }

`;
export default Faq;
