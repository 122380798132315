import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchProjectDataDetail} from "../../api/redux/project";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import DetailsProject from "../../components/project/DetailsProject";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.projectReducer)
    let {slug} = useParams();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PROJECT_DETAILS
        dispatch(fetchProjectDataDetail([api_url + `/${slug}`]))
    }, [])


    let details = getData?.detail?.[0];
    let meta = details?.page_meta;

    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : ''}`}</title>

                {
                    meta?.meta_desc &&
                    <meta name="description" content={meta?.meta_desc}/>

                }
                {
                    meta?.og_title &&
                    <meta property="og:title" content={meta?.og_title}/>

                }
                {
                    meta?.og_desc &&
                    <meta property="og:description" content={meta?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <DetailsProject data={details}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
