import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Quality from "../../components/about/Quality";
import AboutUs from "../../components/about/AboutUs";
import Award from "../../components/about/Award";
import CorporateValues from "../../components/about/CorporateValues";
import Message from "../../components/about/Message";
import Team from "../../components/about/Team";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAboutData} from "../../api/redux/about";
import overview from "../../components/home/Overview";
import {gsap} from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const MyComponent = () => {

    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT
        dispath(fetchAboutData([api_url]))
    }, [dispath])


    let getPost = useSelector(store => store.aboutReducer)



    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let page_data = data?.page_meta;
    let banner = data?.banner;
    let overview = data?.overview;
    let quality = data?.mission_vision;
    let corporate = data?.corporate_value;
    let award = data?.award;
    let md = data?.md;
    // let team = data?.team;


    //scroll to section
    const location = useLocation()

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    useEffect(() => {
        if (location.hash && !getPost?.loading) {
            gsap.to(window, {
                // delay: .6,
                duration: .8,
                scrollTo: location.hash,
                // ease: "Expo.easeInOut",
            })
        }
    }, [location, getPost])


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title : ''}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    mobImg = {banner?.image_mob}
                    img={banner?.image}
                    title={banner?.title}
                    subtitle={banner?.description}
                />
                <AboutUs data={overview}/>
                <Message data={md}/>
                <Quality data={quality}/>
                <CorporateValues data={corporate}/>
                <Award data={award}/>

                {/*<Team  data={team}/>*/}


            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
