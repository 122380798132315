import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Col, Container, Row} from "react-bootstrap";

const MyComponent = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>CONCORD Ready Mix</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                <Container>
                    <Row>
                        <Col md={12} className={'title'}>
                            <h3>Font Testing</h3>
                        </Col>
                        <Col md={12}>
                            <ul>
                                <li>
                                    <h1>H1</h1>
                                </li>
                                <li>
                                    <h2>H2</h2>
                                </li>
                                <li>
                                    <h3>H3</h3>
                                </li>
                                <li>
                                    <h4>H4</h4>
                                </li>
                                <li>
                                    <h5>H5</h5>
                                </li>
                                <li>
                                    <p className={'menu'}>Menu/Button</p>
                                </li>
                                <li>
                                    <p>P(Body Text)</p>
                                </li>
                                <li>
                                    <p className={'meta'}>Meta Data</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
    .title{
      margin-top: 80px;
      margin-bottom: 80px;
    }
  li{
    margin-bottom: 40px;
    .menu{
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }
    .meta{
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }
  }
  
`;

export default MyComponent;
