import React from 'react';
import styled from "styled-components";

const Logo = () => {
    return (
        <StyledLogo>
            <svg xmlns="http://www.w3.org/2000/svg" width="8.486" height="8.485" viewBox="0 0 8.486 8.485">
                <g id="Cross" transform="translate(1057.832 -2206.173) rotate(45)">
                    <line id="Line_10" data-name="Line 10" x2="10" transform="translate(813 2308)" fill="none" stroke="#25292c" stroke-linecap="round" stroke-width="1"/>
                    <line id="Line_11" data-name="Line 11" x2="10" transform="translate(818 2303) rotate(90)" fill="none" stroke="#25292c" stroke-linecap="round" stroke-width="1"/>
                </g>
            </svg>
        </StyledLogo>
    );
};

const StyledLogo = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #FCFEFF;
    g{
      transition: 0.3s all ease;
    }
  
  &:hover{
    #Hover{
      stroke-dasharray: 180px 200px;
      opacity: 1;
    }
  }
`;

export default React.memo(Logo);
