import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pb-200 pt-200'} id={'overview'}>
            <Container>
                <Row>
                    <Col>
                        <h3 className={'split-up'}>
                            {reactHtmlParser(data?.title)}
                        </h3>
                        {reactHtmlParser(data?.description)}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #F7F3F0;
  
  h3{
    color: #25292C;
    margin-bottom: 60px;
  }
  p{
    color: #25292C;
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 20px;
    }
  }
`;

export default React.memo(MyComponent);
