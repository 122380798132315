import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import VideoSingle from "./VideoSingle";
import Button from "../Button";
import { text } from "../../styles/globalStyleVars";
import PopupV1 from "../educate/PopUpModal";
import Title from "../Title";
import 'react-modal-video/css/modal-video.min.css';

const MyComponent = ({ data }) => {
    const [videoId, setVideo] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true);
        setVideo(e?.url);
    }



    return (
        <StyledComponent className={'pt-80 pb-200'}>
            <Container>
                <Row>
                    {data && data?.length > 0 && data?.map((e, i) => {
                        return (
                            <Col md={4} onClick={() => handleShow(e)} key={i}>
                                <VideoSingle
                                    title={e?.title}
                                    img={e?.thumb} />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
            <PopupV1 show={show} video={videoId} direct_video={videoId} handleClose={handleClose} />
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F7F3F0;

  .col-md-4 {
    &:nth-last-child(-n+3) {
      .gallery {
        margin-bottom: 0;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }
      }
    }
  }
`;

export default React.memo(MyComponent);
