import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
// Internal Component
import {Img} from "../Img";
import {Col, Container, Row, Table} from "react-bootstrap";
import Button from "../Button";
import {useSelector} from "react-redux";
import reactHtmlParser from "react-html-parser";
import {title} from '../../styles/globalStyleVars';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import {Link} from "react-router-dom";

const ProductInfo = ({data}) => {

    let [shareUrl, setShareUrl] = useState('')

    let shareButtonClickM = useRef()
    let shareButtonContentM = useRef()
    let shareToggle = useRef()


    useEffect(() => {
        setShareUrl(window.location.href)
    }, [])

    useEffect(() => {
        shareButtonClickM.current.addEventListener('click', (e) => {
            shareToggle.current.classList.toggle('open')
        })
    }, [shareButtonContentM])



    return (
        <>
            <StyledProductInfo className={'detail pt-200 pb-200'}>
                <Container>
                    <Row>
                        <Col sm={4} className={'pr-0'}>
                            <div className={'ProductSlider__single_item'}>
                                <Img src={data?.image}  layout={'fill'}/>
                            </div>
                        </Col>

                        {/*description*/}
                        <Col sm={{span: 7, offset: 1}} className={'pl-0 detail__desc'}>
                            <div className="share_wrapper d-flex">
                                <div className="share hover" ref={shareButtonClickM}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
                                        <g id="Component_45_1" data-name="Component 45 – 1"
                                           transform="translate(0.5 0.5)">
                                            <g id="share" transform="translate(6 7)">
                                                <g id="Group_15815" data-name="Group 15815">
                                                    <path id="Path_2247" data-name="Path 2247"
                                                          d="M12,8A3.986,3.986,0,0,0,8.994,9.367L6.455,7.8a3.131,3.131,0,0,0,.117-1.9L9.947,4.349a2.652,2.652,0,1,0-.613-1.682,2.711,2.711,0,0,0,.047.474L6.01,4.7a3.333,3.333,0,1,0-.252,4.245L8.293,10.5A4,4,0,1,0,12,8Zm0-6.667a1.333,1.333,0,1,1-1.333,1.333A1.335,1.335,0,0,1,12,1.333ZM3.333,8.667a2,2,0,1,1,2-2A2,2,0,0,1,3.333,8.667Zm8.667,6A2.667,2.667,0,1,1,14.667,12,2.669,2.669,0,0,1,12,14.667Z"/>
                                                </g>
                                            </g>
                                            {/*<circle id="Ellipse_449" data-name="Ellipse 449" cx="15" cy="15" r="15"*/}
                                            {/*        fill="none" stroke="#010a1a" stroke-width="1"/>*/}
                                        </g>
                                    </svg>
                                </div>
                                <div className="social-lists" ref={shareToggle}>
                                    <FacebookShareButton url={shareUrl}><FacebookIcon size={32}
                                                                                      round={true}/></FacebookShareButton>
                                    <LinkedinShareButton url={shareUrl}><LinkedinIcon size={32}
                                                                                      round={true}/></LinkedinShareButton>
                                    <TwitterShareButton url={shareUrl}><TwitterIcon size={32}
                                                                                    round={true}/></TwitterShareButton>
                                    <EmailShareButton url={shareUrl}><EmailIcon size={32}
                                                                                round={true}/></EmailShareButton>
                                </div>

                            </div>
                            <div className="detail__desc__title">
                                <h5>{data?.post_title}</h5>
                                <p>{'Project'}</p>
                            </div>
                            <div className="detail__desc__spec">
                                <div className={'multi-para'}>
                                    {reactHtmlParser(data?.description)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledProductInfo>
        </>

    );
};

const StyledProductInfo = styled.section`
  padding-top: 190px;
  background-color: #F7F3F0;
  //padding-bottom: 60px;

  .share_wrapper {
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 767px) {
      //position: relative;
      right: 15px;
      //margin-bottom: 10px;
      
    }

    .download {
      margin-left: 15px;
      height: 31px;
      width: 31px;
      border: 1px solid #010a1a;
      border-radius: 50%;
      &:hover{
        border: 1px solid #3CB649;
      }
    }

    .share {
      margin-left: 12px;
      height: 31px;
      width: 31px;
      border: 1px solid #010a1a;
      border-radius: 50%;
      &:hover{
        border: 1px solid #3CB649;
      }
    }

    .social-lists {
      opacity: 0;
      display: flex;
      height: 0;
      visibility: hidden;

      &.open {
        opacity: 1;
        visibility: visible;
        height: 100%;
      }

      transition: 0.7s all ease;
      flex-direction: column;
      position: absolute;
      right: 0;
      top: 40px;

      .react-share__ShareButton {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .hover {
    cursor: pointer;

    svg {
      position: relative;
      z-index: 1;
    }

    &:hover {
      svg {
        circle {
          stroke: #1E5C95;
        }

        path {
          fill: white;
        }
      }
    }
  }


  .ProductSlider {
    &__single_item {
      position: relative;
      padding-top: calc(370 / 370 * 100%);
      img {
        object-fit: cover;
      }
    }
  }


  

  .detail__desc {
    &__title {
      border-bottom: 1px solid #25292C;
      padding-bottom: 40px;
      
      h1 {
        font-size: 24px;
        font-weight: 500;
        color: #25292C;
        text-transform: uppercase;
        line-height: 28px;
        padding-right: 70px;
      }

      p {
        color: rgb(37 41 44 /50%);
        font-size: 16px;
        line-height: 20px;
        margin-top: 5px;
      }
      h6{
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        color: #3CB649;
        margin-top: 20px;
      }
    }

    &__color {
      //margin-top: 30px;
      padding: 20px 0;
      //border-top: 1px solid #B8B8B8;
      border-bottom: 1px solid #B8B8B8;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #010a1a;
        display: flex;
        padding-right: 20px;

        span {
          color: rgba(1, 10, 26, 0.20);
          margin-right: 10px;
        }
      }

      ul {
        display: flex;

        li {
          height: 28px;
          width: 28px;
          border-radius: 50%;
          border: 1px solid #B8B8B8;
          cursor: pointer;

          &:not(:nth-last-of-type(1)) {
            margin-right: 12px;
          }

          &.active {
            border: 2px solid #010A1A;
          }
        }
      }
    }

    &__spec {
      margin-top: 20px;

      table {
        margin-bottom: 0;
        tr {
          td {
            font-family: ${title};
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: #25292C;
            //text-transform: uppercase;
            width: 50%;
            padding-left: 0;
            padding-right: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            border-color: rgba(184, 184, 184, 0.2);
            border-bottom: 1px solid rgba(184, 184, 184, 0.40);
          }

          &:nth-of-type(1) {
            td {
              padding-top: 0;
              border-top: 0;
            }
          }

          &:nth-last-of-type(1) {
            td {
              padding-bottom: 0;
              border-bottom: none;
            }
          }
        }
      }
      .multi-para{
        margin-top: 40px;
        p{
          font-weight: 400;
          color: #25292C;
          width: 90%;
        }
        h6{
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          color: rgb(37 41 44 / 50%);
          margin-top: 20px;
        }
      }
    }
    @media(max-width: 767px){
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  @media (max-width: 991px) {
    padding-top: 60px;
    .col-sm-6 {
      min-width: 100%;
      padding-right: 15px !important;
    }

    .col-sm-5 {
      min-width: 100%;
      padding-left: 15px !important;
      margin: 0;
    }

    .detail__desc {
      margin-top: 40px;
    }
  }

  @media (max-width: 600px) {
    .detail__desc__color {
      flex-wrap: wrap;

      h3 {
        min-width: 100%;
      }

      ul {
        min-width: 100%;
        margin-top: 20px;

        li:not(:nth-last-of-type(1)) {
          margin-right: 20px;
        }
      }
    }
  }
  
  @media(max-width: 767px){
    padding-top: 105px;
    
    .col-sm-4{
      padding-left: 0;
    }
    .detail_desc{
     
    }
  }


`;

export default React.memo(ProductInfo);
