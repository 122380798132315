import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import {BsPlus} from 'react-icons/bs';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import {Link} from "react-router-dom";
import Button from "../Button";
import {Img} from "../Img";
import IconButton from "../IconButton";
import RightSvg from "../RightSvg";
import LeftSvg from "../LeftSvg";
import reactHtmlParser from "react-html-parser";

const Strength = ({title, subtitle, text, img, full, project, sticky, data}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update the window width when the window is resized
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Add a window resize event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    ScrollTrigger.refresh();
    gsap.registerPlugin(ScrollTrigger);


    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    const NewsPrev = () => {
        document.querySelector('.about-section__bottom .swiper-button-prev').click();
    };

    const NewsNext = () => {
        document.querySelector('.about-section__bottom .swiper-button-next').click();
    };

    return (
        <StyledStrength offset={offset} className={`pionneering parallax-inner-box  pb-200`}>
            <Container >
                <Row>
                    <Col md={6}>
                        <div className="d-flex navigation_wrapper">
                            <div className="title">
                                <h3 className={'split-up'}>{data?.title}</h3>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="content">
                            <p>
                                {reactHtmlParser(data?.desc)}
                            </p>
                            {windowWidth <= 767 &&
                                <IconButton text={'Learn More'}
                                            iconcolor={'#FCFEFF'}
                                            iconbg={'#25292C'}
                                            margintop={'54px'}
                                            src={'/project'}
                                            color={'#25292C'}
                                />
                               }
                        </div>
                    </Col>
                    <div className="clearfix"></div>
                </Row>
                <Row className={'nav-bottom'}>
                    <Col className={'navigation_col'} md={6}>
                        <div className="d-flex navigation_wrapper">
                            <ul className="navigation">
                                <li className={'prev_swipper hover'} onClick={NewsPrev}>
                                    <LeftSvg color={'#25292C'}/>
                                </li>
                                <li className={'next_swipper hover'} onClick={NewsNext}>
                                    <RightSvg color={'#25292C'}/>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        {windowWidth >= 767 ?
                            <IconButton text={'Learn More'}
                                        iconcolor={'#FCFEFF'}
                                        iconbg={'#25292C'}
                                        src={'/project'}
                                        color={'#25292C'}
                            />
                            : ''}
                    </Col>
                </Row>
            </Container>
            <Container fluid className='about-section__bottom'>
                <Row>

                    {
                        data?.project_list &&
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={3}
                            slideNextClass={'.next'}
                            allowSlideNext={true}
                            slidePrevClass={'.prev'}
                            allowSlidePrev={true}
                            allowTouchMove={true}
                            longSwipesMs={900}
                            // loop={true}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                            navigation={true} modules={[Navigation]}
                            // onSlideChange={() => console.log('slide change')}
                            // onSwiper={(swiper) => console.log(swiper)}

                        >
                            {
                                data?.project_list?.length>0 &&
                                data?.project_list?.map((e,i)=>{
                                    return(
                                        <SwiperSlide key={i}>
                                            <Col  className='about-section__bottom__single p-0'>

                                                <div className="about-section__bottom__single__inner">
                                                    <Link to={`/project/${e?.post_name}`}></Link>
                                                    <div className="about-section__bottom__single__inner__img">
                                                        <Img src={e?.thumb}/>
                                                    </div>

                                                    <div className="circle">
                                                        <BsPlus/>
                                                    </div>
                                                    <p>{reactHtmlParser(e?.hover_text)}</p>
                                                    <h5>{reactHtmlParser(e?.post_title)}</h5>
                                                    <h2>{i+1}</h2>
                                                    <div className="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.207" height="11.414"
                                                             viewBox="0 0 11.207 11.414">
                                                            <g id="Group_15992" data-name="Group 15992" transform="translate(0.5 0.85)">
                                                                <line id="Line_3858" data-name="Line 3858" x2="5" y2="5"
                                                                      transform="translate(5 -0.143)" fill="none" stroke="#f9f9f9"
                                                                      stroke-linecap="round" stroke-width="1"/>
                                                                <line id="Line_3859" data-name="Line 3859" y1="5" x2="5"
                                                                      transform="translate(5 4.857)" fill="none" stroke="#f9f9f9"
                                                                      stroke-linecap="round" stroke-width="1"/>
                                                                <line id="Line_3860" data-name="Line 3860" x1="10"
                                                                      transform="translate(0 4.857)" fill="none" stroke="#f9f9f9"
                                                                      stroke-linecap="round" stroke-width="1"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </div>
                                            </Col>

                                        </SwiperSlide>
                                    );
                                })
                            }

                        </Swiper>
                    }


                </Row>
            </Container>
        </StyledStrength>
    );
};

const StyledStrength = styled.section`
  background: #F7F3F0;
  position: relative;
  overflow: hidden;
  padding-top: 200px;
  @media(max-width: 767px){
    padding-top: 100px;
  }
  &:after{
    height: calc(50vh - 40px);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #F7F3F0;
    content: "";
    z-index: 2;

    @media(max-width: 767px){
      height: calc(50vh - 160px);
    }
  }
  .background{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top:0;
    z-index: 1;
   height: clac(100vh - 20vh);
    img{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top:0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .title_wrapper {
    display: flex;
  }

  .swiper-initialized{
    padding-right: 120px;
    @media(max-width: 767px){
      padding-right: 0 !important;
    }
  }
  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .nav-bottom{
    margin-top: 60px;
    margin-bottom: 60px;
  }
  
  .content{
    p{
      font-size: 18px;
      font-weight: 400;
      color: #25292C;
    }
    
    @media(max-width: 767px){
      margin-top: 20px;
    }
  }
  
  .row{
    width: 100%;
  }
  .swiper{
    margin: 0;
    width: 100%;
  }
  .navigation_wrapper {
    align-items: center;
    .title {
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #222222;
      }
    }

    .d-flex {
      justify-content: space-between;
    }

    .navigation {
      display: flex;
      gap: 24px;
      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid #25292C;

        &.hover:after {
          background-color: #3CB649;
        }
        
        position: relative;
        border-radius: 50%;
        height: 40px;
        width: 40px;

        svg {
          position: relative;

          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 26px;
            }
          }
        }
        &:hover{
          border: 1px solid #67A66D;
          svg{
            g{
              line{
                stroke:  #FCFEFF;
              }
            }
          }
        }
      }
    }
  }

  .container-fluid{
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
    padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
    position: relative;
    z-index: 3;
    @media(max-width: 767px){
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  .about-section__bottom {
    //margin-top: -50px;
    padding-right: 0 !important;
    padding-left: ${props => props.offset ? props.offset + 30 + 'px' : '90px'};
    @media(max-width: 767px){
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    &__single {
      max-width: 100%;
      &__inner {
        position: relative;
        padding-top: calc(370 / 370 * 100%);
        overflow: hidden;
        border-right: 1px solid #FFF;

        &:after {
          content: '';
          background-color: #3CB649;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 0%;
          width: 100%;
          transition: .4s ease;
          //mix-blend-mode: multiply;
        }

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0px;
          z-index: 5;
        }

        &__img {
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.4);;
            mix-blend-mode: multiply;
            opacity: 1;
            transition: .8s ease;
            transition-delay: .3s;
          }
        }

        p {
          position: absolute;
          left: 30px;
          right: 30px;
          top: 70px;
          color: #ffffff;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          transform: translateY(-100%);
          transition: all .5s ease;
          opacity: 0;
        }

        .img-top {
          position: absolute;
          top: 40px;
          left: 30px;
          z-index: 2;
          opacity: 1;
          transition: .3s ease;
          height: 60px;
        }

        .circle {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          height: 50px;
          width: 50px;
          border: 1px solid #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          opacity: 1;
          transition: .3s ease;

          svg {
            color: #ffffff;
            font-size: 25px;
            transition: .3s ease;
          }
        }

        h5 {
          position: absolute;
          bottom: 30px;
          left: 40px;
          right: 40px;
          font-size: 24px;
          line-height: 28px;
          font-weight: 400;
          color: #FAFAFA;
          z-index: 2;
          transition: all .5s ease;

        }

        .icon {
          position: absolute;
          top: 30px;
          right: 30px;
          z-index: 1;
          transition: all .8s ease;
          transform: rotate(0deg);

          svg {
            li {
              transition: all .8s ease;

            }
          }

        }

        h2 {
          position: absolute;
          z-index: 1;
          top: 40px;
          left: 40px;
          font-weight: 500;
          color: #FAFAFA;
          transition: all .5s ease;

        }

      }

      &:hover {
        .about-section__bottom__single__inner {
          &:after {
            height: 0;
          }

          .icon {
            transform: rotate(-45deg);

            svg {
              line {
                stroke: #F9F9F9;
                fill: #F9F9F9;
              }
            }
          }

          h2 {
            top: -10px;
            opacity: 0;
          }

          h4 {
            color: #FCFEFF !important;
          }

          p {
            transform: none;
            opacity: 1;
            color: #FCFEFF;
          }


          &__img:after {
            opacity: 1;
            transition-delay: 0s;
          }

          .img-top {
            transform: translateY(-20px);
            opacity: 0;
          }

          .circle {
            transform: scale(1.9);
            opacity: 0;

            svg {
              transform: rotate(50deg);
            }
          }

        }

      }
    }
  }

`;

export default React.memo(Strength);
