import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ProductBox from "../product/ProductBox";
import SubTitle from "../SubTitle";
import IconButton from "../IconButton";
import LeftSvg from "../LeftSvg";
import RightSvg from "../RightSvg";
import router from "react-router-dom/es/Router";

const Slider = ({padding, data, id}) => {


    const subtitle = data?.section_data?.subtitle;
    const title = data?.section_data?.short_desc;
    const desc = data?.section_data?.description;
    const slide = data?.posts?.list;
    const [show, setShow] = useState(false);

    // offset get
    const [offset, setOffset] = useState(90)
    useEffect(() => {
        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })

        setOffset(document.querySelector(' .container').offsetLeft)


    }, [data, router.pathname])

    const NewsgoPrev = () => {
        // document.querySelector('.slider_left .swiper-button-prev').click();
        document.querySelector('.right_side .swiper-button-prev').click();

    };

    const NewsgoNext = () => {
        // document.querySelector('.slider_left .swiper-button-next').click();
        document.querySelector('.right_side .swiper-button-next').click();

    };



    return (

        <StyledBox id={`${id ? id : 'SliderBox'}`} className={`slider_component ${padding ? padding : 'pt-200 pb-200'}`}>
            <Container fluid className={'version_one p-0'}>
                <Row>
                    <Col md={4} className={'slider_left'}>
                        <div style={{paddingLeft: offset + 30 + 'px'}} className="left_side_content_wrapper">
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={1}
                                slideNextClass={'.next'}
                                allowSlideNext={true}
                                slidePrevClass={'.prev'}
                                allowSlidePrev={true}
                                loopp={true}
                                allowTouchMove={true}
                                longSwipesMs={900}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                }}
                                navigation={true} modules={[Navigation]}
                                // onSwiper={(swiper) => console.log(swiper)}
                            >
                                <SwiperSlide key="0">
                                    <div className="content_wrapper_slider" >
                                        <SubTitle text={data?.title}/>
                                        <div className="product_desc">
                                            <p className={'split-up'}>
                                                {reactHtmlParser(data?.description)}
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>


                        <div className={' slider_nav bottom-nav d-flex'} style={{left: offset + 30 + 'px'}}>
                            <ul  className="desktop d-flex fade-up">
                                <li onClick={NewsgoPrev} className={'slider_prev hover'}>
                                    <LeftSvg/>


                                </li>
                                <li onClick={NewsgoNext} className={'slider_next hover'}>
                                   <RightSvg/>
                                </li>
                            </ul>
                            <IconButton text={'View All'} src={'/product'} color={'#FCFEFF'}/>
                        </div>
                    </Col>
                    <Col md={8} className='right_side'>
                        {
                            data?.feature_products &&
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={2}
                                slideNextClass={'.next'}
                                allowSlideNext={true}
                                slidePrevClass={'.prev'}
                                allowSlidePrev={true}
                                allowTouchMove={true}
                                longSwipesMs={900}
                                loop={true}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                }}
                                navigation={true} modules={[Navigation]}
                                // onSwiper={(swiper) => console.log(swiper)}

                            >
                                {
                                    data?.feature_products?.length>0 &&
                                    data?.feature_products?.map((e,i)=>{
                                        return(
                                            <SwiperSlide className={'single_image_image'} key={i} >
                                                <ProductBox
                                                    title={e?.post_title}
                                                    text={e?.size}
                                                    img={e?.product_thumb}
                                                    url={e?.post_name}
                                                />
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        }

                    </Col>
                </Row>
            </Container>



        </StyledBox>

    )
};


const StyledBox = styled.section`
  background: #004185;
  overflow: hidden;
  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }
  
  .left_side_content_wrapper{
    .swiper{
      overflow: unset;
      margin-right: 100px;
      @media(max-width: 767px){
        margin-right: 0;
      }
      .swiper-wrapper{
        .swiper-slide{
          margin-right: 0 !important;
          width: 100% !important;
        }
      }
    }
  }

  .slider_left {
    //padding-right: 30px;
    //padding-right: 85px;
    //overflow: hidden;
    padding: 0;
    .product_desc{
      p{
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #FCFEFF;
      }
    }

    
  }

  .right_side {
    .swiper-initialized{
      padding-right: 100px;
      @media(max-width: 992px){
        padding-right: 0;
      }


    }


  }

  ul{
    gap: 24px;
    position: relative;
    display: flex;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid rgb(252 254 255 / 50%);

      &.hover:after {
        background-color: #3CB649 !important;
      }


      position: relative;
      border-radius: 50%;
      height: 40px;
      width: 40px;

      svg {
        position: relative;

        #Ellipse_4378 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          r: 0;
        }

        &:hover {
          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 26px;
          }
        }
      }
    }
  }
  

  .version_one {
    .title {
      flex-direction: column-reverse;
    }

    .row {
      position: relative;
    }

    .slider_left {
      position: relative;
      @media (max-width: 767px) {
        padding: 0px 15px !important;
      }

      .slider_nav {
        align-items: center;
        gap: 85px;
        position: absolute;
        left: 15px;
        bottom: 0;
        z-index: 3;
      }
    }

    .full_wrapp {
      background: #F2F2F2;
      //padding: 40px;
      margin-bottom: 90px;
      position: relative;
      cursor: pointer;
      height: 100%;
      span {
        font-size: 160px;
        font-weight: 500;
        line-height: 160px;
        color: #000;
        opacity: 20%;
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        left: 0;
        right: 0;
      }

      h6 {
        padding-top: 40px;
        font-size: 24px;
        color: #000000;
        font-weight: 500;
        margin-bottom: 34px;
        text-align: center;
      }

      img{
        width: 100% !important;
      }
      //.single_image_image_wrp {
      //  position: relative;
      //  padding-top: calc(370 / 370 * 100%);
      //  //margin-bottom: 50px;
      //  img{
      //    object-fit: contain;
      //  }
      //}
    }


  }


  @media(max-width: 1180px) and (min-width: 768px){

    .slider_nav{
      bottom: -90px !important;
    }
  }
  @media(max-width: 992px) and (min-width: 768px){
    .version_one .slider_left , .version_one .right_side {
      flex: 0 0 50%;
      max-width: 50%;
    }

  }

  @media (max-width: 767px) {
    .container-fluid {
      padding: 0 15px !important;
    }

    .left_side_content_wrapper {
      padding: 0 !important;
    }
    .slider_nav{
      margin: 40px 0;
      position: relative !important;
      left: 0 !important;
    }

    .mobile {
      margin-top: 30px;
      display: flex !important;
    }



    overflow: hidden;
  }

  .box_global{
    margin-bottom: 0;
  }

`;


export default React.memo(Slider);