import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {
    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])
    return (
        <StyledComponent offset={offset}>
            <Container fluid className={'award'}>
                <Row>
                    <Col md={6} className={'award__left'}>
                        <div className={'award__left__content'}>
                            <h3 className={'split-up'}>{reactHtmlParser(data?.title)}</h3>
                        </div>
                    </Col>
                    <Col md={6} className={'award__right'}>
                        <div className={'img-wrapper'}>
                            <Img src={data?.image}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  //padding-right: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
  position: relative;
  //&:after{
  //  height: 50%;
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //  right: 0;
  //  background: #F7F3F0;
  //  content: "";
  //  z-index: -1;
  //}
    .award{
      overflow: hidden;
      &__left{
        background-color: #004185;
        padding-left: ${props => props.offset ? props.offset + 15 + 'px' : '90px'};
        &__content{
          display: flex;
          align-items: center;
          height: 100%;
          width: 70%;
          h3{
            font-weight: 500;
            color: #FAFAFA;
          }
        }
      }
      &__right{
        padding-left: 0;
        padding-right: 0;
        .img-wrapper{
          position: relative;
          padding-top: 100%;
        }
      }
    }
  @media(max-width: 767px){
    padding-right: 0;
    .award{
      &__left{
        padding-left: 0;
        &__content{
          width: 100%;
          padding: 30px 50px;
            h3{
                font-size: 38px !important;
                line-height: 44px !important;
            }
        }
      }
      &__right{
        padding-right: 0;
      }
    }
  }
`;

export default React.memo(MyComponent);
