import {Route, Switch, useHistory, useLocation, useParams} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Menu from "./components/Menu"
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


// page imports
import About from './pages/about';
import Components from './pages/components';
import Home from './pages/home';
import Clients from './pages/clients'
import Cost from './pages/cost-reduction-chart'
import Contact from './pages/contact'
import Faq from './pages/faq'
import Refund from './pages/refund'
import Educate from './pages/how-to-educate'
import Tracking from './pages/order-tracking'
import Career from './pages/career'
import Project from './pages/project';
import ProjectDetail from './pages/project/single';
import Media from './pages/media';
import MediaDetails from './pages/media/single';
import Product from './pages/product';
import ProductDetail from './pages/product/single';
import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import PageTransition from "./components/PageTransition";
import MobileMenu from "./components/MobileMenu";
import Footer from "./components/Footer";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "./api/network/apiEndPoints";
import footerReducer, {fetchFooter} from "./api/redux/footer";

function App() {

    const location = useLocation();
    let getPost = useSelector(state => state)
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    const dispatch = useDispatch();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.GLOBAL_SETTING;
        dispatch(fetchFooter([api_url]));
    }, []);

    const getfooter = useSelector((state) => state.footerReducer);
    const footerData = getfooter?.posts?.page_data?.[0];


    // smooth scroll init
    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: window.innerWidth > 767 ? 2 : '', // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: window.innerWidth > 767 ? true : false, // looks for data-speed and data-lag attributes on elements
            // speed: 2
        });
        return () => {
            smoother.kill();
        };
    }, [location.pathname]);

    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page')
        } else {
            document.body.classList.remove('in-home-page')
        }

        window.scroll(0, 0)
    }, [location.pathname])


    //canonical link generate
    useEffect(() => {
        // Get the current URL from the router's full path
        const currentUrl = window.location.origin + location.pathname + location.search;

        // Set the canonical link dynamically
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = currentUrl;
        document.head.appendChild(link);

        // Clean up the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, [location.pathname, location.search]);


    // animation functions
    ImageReveal()
    Parallax()
    ParallaxImg()
    SplitUp()
    // page transition
    PageTransition()


    const [textColor, setTextColor] = useState('#25292C')
    const [menuColor, setMenuColor] = useState('#F7F3F0');
    const { slug } = useParams();
    const history= useHistory();



    useEffect(() => {
        if (
            history?.location?.pathname.startsWith(`/product/`)  ||
            history?.location?.pathname.startsWith(`/project/`) ||
            location.pathname === '/refund-policy'
        ) {
            setMenuColor('#F7F3F0');
            setTextColor('#25292C');
        } else {
            setMenuColor('transparent');
            setTextColor('#F7F3F0');
        }
    }, [location.pathname, slug]);





    const [isLoadingContent, setIsLoadingContent] = useState(true);
    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        setIsLoadingContent(true); // Show preloader when route changes
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoadingContent(false); // Set isLoadingContent to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, [location?.pathname]);

    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container')?.offsetLeft)
    }, [])


    //canonical link generate
    useEffect(() => {
        // Get the current URL from the router's full path
        const currentUrl = window.location.origin + location.pathname + location.search;

        // Set the canonical link dynamically
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = currentUrl;
        document.head.appendChild(link);

        // Clean up the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, [location.pathname, location.search]);


    return (
        <>
            <GlobalStyle/>
            <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>

            {
                isLoadingContent ? (
                    <PageTransition/>
                ) : ''
            }


            <div className="App" ref={el} id="smooth-wrapper">
                <Menu color={menuColor} textColor={textColor} social={footerData?.social}/>
                <MobileMenu color={'#ffffff'}/>
                <div id="smooth-content">
                    <Switch location={location} key={'location.pathname'}>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/components" component={Components}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/clients" component={Clients}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/faq" component={Faq}/>
                        <Route exact path="/refund-policy" component={Refund}/>
                        <Route exact path="/how-to-educate" component={Educate}/>
                        <Route exact path="/order-tracking" component={Tracking}/>
                        <Route exact path="/cost-reduction-chart" component={Cost}/>
                        <Route exact path="/career" component={Career}/>
                        <Route exact path={`/project`} component={Project}/>
                        <Route exact path={`/project/:slug`} component={ProjectDetail}/>
                        <Route exact path={`/media`} component={Media}/>
                        <Route exact path={`/media/:slug`} component={MediaDetails}/>
                        <Route exact path={`/product`} component={Product}/>
                        <Route exact path={`/product/:slug`} component={ProductDetail}/>
                        <Route component={Error}/>
                    </Switch>
                    <Footer data={footerData}/>
                </div>

            </div>
        </>


    );
}

export default App;
