import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Quality from "../../components/about/Quality";
import AboutUs from "../../components/about/AboutUs";
import Award from "../../components/about/Award";
import CorporateValues from "../../components/about/CorporateValues";
import Message from "../../components/about/Message";
import Team from "../../components/about/Team";
import {Col, Container, Row} from "react-bootstrap";
import Zigzag from "../../components/cost/Zigzag";
import {title} from '../../styles/globalStyleVars';
import ZigzagLast from "../../components/cost/ZigzagLast";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchCostData} from "../../api/redux/cost";
import reactHtmlParser from "react-html-parser";

const MyComponent = () => {

    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.COST
        dispath(fetchCostData([api_url]))
    }, [dispath])


    let getPost = useSelector(store => store.costReducer)

    //refactor
    let data = getPost?.posts?.page_data?.[0];
    let page_data = data?.page_meta;
    let banner = data?.banner;
    let chart = data?.cost_reduction_list?.list;
    let coloredChart = data?.cost_reduction_list_two?.list;

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title : ''}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    img={banner?.image}
                    title={banner?.title}
                    subtitle={banner?.description}
                />
                <div className={'top-content pb-120 pt-120'}>
                    <Container>
                        <h4 className={'split-up'}>
                            {reactHtmlParser(data?.overview?.title)}
                        </h4>
                    </Container>
                </div>
                {
                    chart && chart?.length>0 &&
                    chart?.map((e, i)=>{
                        return(
                            <Zigzag key={i}
                                title={e?.title}
                                subtitle={e?.subtitle}
                                desc={e?.table}
                            />
                        );
                    })
                }
                {
                    coloredChart && coloredChart?.length>0 &&
                    coloredChart?.map((e, i)=>{
                        return(
                            <ZigzagLast key={i}
                                title={e?.title}
                                        desc={e?.table}
                            />
                        );
                    })
                }
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
    .top-content{
      background-color: #004185;
      h4{
        color: #FCFEFF;
      }
    }
  .zigzag{
  
    &:nth-child(2n){
      background-color: #F7F3F0;
    }
  }
`;

export default MyComponent;
