import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import CareerModal from "../CareerModal";
import IconButton from "../IconButton";
import reactHtmlParser from "react-html-parser";


const Job = ({data}) => {

    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState(null)

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true)
        setPopupId(e)
    }




    return (
        <StyledOverviewInner className={`jobs pt-200 pb-200`}>
            <CareerModal show={show} data={popupId} handleClose={handleClose}/>
            <Container>
                <Row>
                    <Col md={{span: 6, offset: 6}}>
                        <Title text={'Vacancies'} margin={'0 0 60px'} color={'#25292C'}/>
                    </Col>
                    <Col md={12}>
                        <div className="list">
                            {
                                data && data?.length>0 &&
                                data?.map((e,i)=>{
                                    return(
                                        <div className="single-item" key={i}>
                                            <h5 className={'split-up'}>{reactHtmlParser(e?.post_title)}</h5>
                                            <div className="time">
                                                <p className={'split-up'}>{`Status: ${e?.status}`}</p>
                                                <p className={'split-up'}>{`Experience: ${e?.experience}`}</p>
                                            </div>
                                            <div className="button" onClick={() => handleShow(e)}>
                                                <IconButton
                                                    src={'#'}
                                                    margin={'0'}
                                                    color={'#25292C'}
                                                    iconcolor={'#FCFEFF'}
                                                    iconbg={'#25292C'}
                                                    text={'View Details'}
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </Col>
                </Row>

            </Container>
        </StyledOverviewInner>
    )
};

const StyledOverviewInner = styled.div`
  position: relative;
  overflow: hidden;
  background: #F1EEE9;

  .list {
    .single-item {
      display: flex;
      //justify-content: space-between;
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid rgba(198, 198, 198, 0.5);

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      h5 {
        font-weight: 500;
        width: 50%;
        color: #26201E;
        letter-spacing: 0;
      }

      .time {
        width: 30%;

        p {
          margin-bottom: 10px;
          color: #3CB649;


          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .button {
        //width: 30%;
      }
    }

    @media (max-width: 992px) {
      .single-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 40px;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }

        h5 {
          margin-bottom: 20px;
          width: 100%;
        }

        .time {
          width: 100%;

          p {
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .button {
          margin-top: 20px;
          //width: 30%;
        }
      }

    }
  }



`;


export default React.memo(Job);
