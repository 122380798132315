import React from 'react';
import styled from "styled-components";
import {Container, Row, Col, Table} from "react-bootstrap";
import {title} from '../../styles/globalStyleVars';
import reactHtmlParser from "react-html-parser";

const Zigzag = ({title, subtitle, desc}) => {
    return (
        <StyledComponent className={'zigzag pb-120 pt-120'}>
            <Container>
                <h4 className={'split-up'}>{title}</h4>
                <p className={'split-up'}>{subtitle}</p>
                {reactHtmlParser(desc)}
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  background-color: #FCFEFF;
  h4{
    color: #3CB649;
  }
  p{
    margin-top: 20px;
    color: #25292C;
  }
  table {
    margin-top: 60px;
    margin-bottom: 0;
    tr {
      td {
        font-family: ${title};
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #25292C;
        //text-transform: uppercase;
        width: 33.33%;
        padding-left: 0;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-color: rgba(184, 184, 184, 0.2);
        border-bottom: 1px solid #25292C;
      }

      &:nth-of-type(1) {
        td {
          padding-top: 0;
          border-top: 0;
        }
      }
      &:last-child{
        td{
          border-bottom: 0;
        }
      }
    }
  }
`;

export default React.memo(Zigzag);
