import {createGlobalStyle, css} from 'styled-components';
import {hover, text, title} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }


  html {
    scrollbar-color: ${hover} #FEF8F4;
    scrollbar-width: thin;
  }


  @font-face {
    font-family: 'PP Fragment Serif';
    src: url('/fonts/PPFragment-SerifLight.woff2') format('woff2'),
    url('/fonts/PPFragment-SerifLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  

  body {
    font-family: ${title};
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;

    /* Hide scrollbar for Chrome, Safari and Opera */

    //&::-webkit-scrollbar {
    //  display: none;
    //}
    //
    ///* Hide scrollbar for IE, Edge and Firefox */
    //-ms-overflow-style: none; /* IE and Edge */
    //scrollbar-width: none; /* Firefox */
    transition: 1s ease-in-out;
    &.menu-open{
      overflow: hidden;
      //transition: 1s ease-in-out;
    }
  }

  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  h1 {
    font-family: ${title};
    font-size: 120px !important;
    line-height: 120px;
    font-weight: 600;
    margin: 0;

    span {
      color: #18A354;
    }
  }

  h2 {
    font-family: ${title};
    font-size: 60px !important;
    line-height: 68px;
    font-weight: 300;
    margin: 0;

    span {
      color: #18A354;
    }
  }

  h3 {
    font-family: ${title};
    font-size: 40px !important;
    line-height: 48px;
    font-weight: 500;
    margin: 0;

    span {
      color: #18A354;
    }
  }

  h4 {
    font-family: ${title};
    font-size: 32px !important;
    line-height: 36px;
    font-weight: 500;
    margin: 0;

    span {
      color: #18A354;
    }
  }

  h5 {
    font-family: ${title};
    font-size: 24px !important;
    line-height: 28px;
    font-weight: 500;
    margin: 0;

    span {
      color: #18A354;
    }
  }

  p, a, h6 {
    color: ${text};
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin: 0;
  }

  // h1, h2 {
  //     //font-family: ${title};
  //   margin: 0;
  // }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 1px solid #FCFEFF;
    -webkit-text-fill-color: #FCFEFF;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-120 {
    padding-bottom: 120px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  .gph_modal {
    background: transparent;
    overflow: hidden !important;
    z-index: 9999;

    .hover {
      height: 56px;
      width: 56px;
      background-color: #fff !important;
      border-radius: 50%;

      svg {
        //font-size: 17px;
      }
    }

    .modal-body {
      padding-top: 60px !important;
      @media (max-width: 1200px) {
        .col-lg-5 {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
      @media (max-width: 767px) {
        .col-lg-5, .col-sm-12 {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }

        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .modal-dialog {
      margin: 0;
      width: 100%;
      height: 100%;
      //background: #F4F4F4;
      max-width: 100%;

      .btn-close {
        display: none;
      }

      .modal-header {
        padding: 0;
        margin-bottom: 0;
        background: #F9F9F9;
        border-bottom: transparent;
        position: relative;

        .h4 {
          color: #222222;
          font-weight: 500;
        }

        .header_wrap {
          display: flex;
          justify-content: space-between;
          align-items: start;
          padding: 80px 0px 20px;
          border-bottom: 1px solid #404040 !important;
          margin: 0px 15px;
          position: relative;

          @media (max-width: 1499px) {
            margin: 0px 0px;

          }


          .btn-circle {
            position: absolute;
            right: -100px;
            top: 20px;
            height: 56px;
            width: 56px;
            border-radius: 50%;
            background-color: ${text};
            @media (max-width: 767px) {
              right: 15px;
              top: 20px;
            }

            svg line {
              transition: .4s ease;
            }

            &:after {
              background-color: ${hover};
            }

            &:hover {
              svg {
                line {
                  stroke: #ffffff;
                }
              }
            }

            //&:hover {
            //  svg {
            //    stroke: red; /* Replace 'red' with your desired color */
            //  }
            //}

            //&:hover {
            //  svg {
            //    stroke: white;
            //  }
            //
            //  .btn-circle:after {
            //    top: 0;
            //  }
            //}
          }


          h3 {
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
            //margin-left: 16px;
            margin-right: 4px;

            @media (max-width: 767px) {
              margin-top: 44px;
              margin-left: 15px;
            }
            @media (min-width: 1550px) {
              font-size: 40px;
              line-height: 56px;
            }
            @media (max-width: 767px) {
              font-size: 24px;
              line-height: 32px;
            }
          }


          .close_button {
            cursor: pointer;
            margin-right: 16px;
            position: absolute;
            top: 20px;
            right: 20px;

            &:after {
              content: '';
              position: absolute;
              height: 0%;
              width: 0%;
              border-radius: 50%;
              color: #FFFFFF;
              background-color: red;
                // background-color: ${hover};
              opacity: 0;
              transition: all .4s ease;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              margin: auto;

            }

            &:hover:after {
              height: 100%;
              width: 100%;
              opacity: 1;

            }

            &:hover {
              line {
                stroke: #FFF;
              }
            }

            //&:hover {
            //  svg {
            //    g {
            //      g {
            //        path {
            //          stroke: #FFFFFF !important;
            //        }
            //      }
            //    }
            //  }
            //
            //}
          }

        }

      }

      .custombar {
        position: relative;

        &:after {
          z-index: 1;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100px;
          background: transparent;
          //background: linear-gradient(to bottom, rgba(244, 244, 244, 0) 0%,
          //rgba(244, 244, 244, 1) 100%);
          //@media (max-width: 767px) {
          //  background: linear-gradient(to bottom, rgba(244, 244, 244, 0) 0%,
          //  rgba(244, 244, 244, 0.5) 100%);
          //}
        }
      }

      .modal-content {
        height: 100vh;
        padding: 0;
        //background: #F9F9F9;
        border: none;

        .left_col {
          padding: 60px 0px 60px;

          .custom-list {
            padding: 0;

            li {

              position: relative;
              padding-left: 20px;
              padding-top: 20px;

              &:not(:last-child) {
                border-bottom: 1px solid rgba(217, 217, 217, 0.5);
                padding-bottom: 20px;

              }

              &:before {
                content: '';
                position: absolute;
                top: 30px;
                left: 0;
                //transform: translate(-50%,-50%);
                margin-top: -5px;
                width: 10px;
                height: 10px;
                box-sizing: content-box;
                background-color: #C1C1C1;
              }
            }

          }

          h3 {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            color: #404040;

            &:not(:first-child) {
              margin-top: 40px;
            }
          }

          p {
            font-weight: 400;
            color: #222222;
            margin-top: 20px;
            //margin-bottom: 40px;

            &.deg {
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              color: #FB030C;
              margin: 0 0 40px;
            }
          }

          @media (max-width: 767px) {

            .content {
              padding-left: 15px;
              padding-right: 15px;
            }

            h5 {
              margin-top: 30px;
            }
          }
        }

        .right_col {
          //padding: 60px 15px 60px;
          margin-left: -15px;
          margin-right: -15px;

          .custombar {
            &:after {
              display: none;
            }
          }

          .right_col_img {
            position: relative;
            padding-top: calc(600 / 500 * 100%);
          }
        }

      }
    }

    .simplebar-track.simplebar-vertical {
      display: none;
    }

    @media (max-width: 767px) {
      overflow-y: scroll !important;
      .custombar {
        position: relative;
        height: auto !important;

        .simplebar-content-wrapper {
          overflow: auto;
        }
      }

      .modal-content {
        overflow: hidden !important;
      }

      .row {
        //flex-direction: column-reverse;

        .left_col {
          padding: 0 0px 40px !important;
        }

        .right_col {
          //padding: 40px 15px !important;
        }
      }

    }

    @media (min-width: 767px) {
      .main_scroll {
        position: relative;
        height: 100% !important;

        .simplebar-content-wrapper {
          overflow: scroll;
        }
      }
    }
  }

  .gph_upload {
    position: relative;
    cursor: pointer;

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      content: attr(text);
      background: #F9F9F9;
      z-index: 1;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: rgba(34, 34, 34, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.7s all ease;
    }

    &:hover {
      &:after {
        background: #E9E9E9;
      }
    }
  }


  .modal-backdrop {
    background-color: transparent;
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }

  .modal {
    padding-left: 0 !important;
  }

  .modal-header {
    padding: 0 0 27px;
    margin-bottom: 40px;
  }

  .modal-dialog {
    max-width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .transparent-modal {
    background-color: rgba(0, 0, 0, 0.5);
  !important;
  }

  .white-modal {
    background-color: #F0EDE3 !important;
  }

  .modal-content {
    border-radius: 0;
    //padding: 50px 70px;
    background-color: transparent;
    border: none;

    h3 {
      margin-bottom: 60px;
    }

    .form-divide {
      justify-content: space-between;

      .form-control {
        width: calc(50% - 15px);
      }
    }

    .modal-close {
      position: absolute;
      overflow: hidden;
      background-color: #000000;
      right: 30px;
      padding: 18px;
      border-radius: 50%;

      &:after {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: #ED5333;
        transition: all .5s ease;
        border-radius: 25px;
      }

      &:hover {
        cursor: pointer;

        svg {
          position: relative;
          z-index: 2;
        }

        &:after {
          height: 100%;
          width: 100%;
        }
      }

      @media (max-width: 767px) {
        top: -60px;
      }
    }
  }

  .modal-body {
    overflow-y: visible;
    padding: 0;
    background-color: transparent;
    border: none;

    .modal-close {
      top: 50px;
      right: 50px;
      @media (max-width: 768px) {
        top: 20px;
        right: 20px;
      }
    }

    .career-cont {
      margin: 100px 100px 60px 100px;
      @media (max-width: 768px) {
        margin: 50px 0 20px;
      }
    }

    .map-cont {

      margin: 100px 100px 60px 100px;
      @media (max-width: 768px) {
        margin: 50px 0 20px;
      }

      .address__accordion {
        h4 {
          font-size: 16px;
          line-height: 22px;
          color: #1C1718;
          margin: 0 0 20px 0;
        }

        p {
          width: 70%;
        }

        p, a {
          font-size: 20px;
          line-height: 24px;
          font-weight: 600;
        }

        .contact-numbers {
          margin-top: 45px;

          ul {
            li {
              a {
                margin-bottom: 13px;
                display: flex;

                span {
                  font-size: 26px;
                  font-weight: bold;
                  line-height: 32px;
                  transition: .3s ease;
                }

                &:hover span {
                  color: ${hover};
                }
              }

              &:nth-last-of-type(1) {
                margin-bottom: 0;
              }
            }
          }
        }

        .map {
          position: relative;
          padding-top: calc(330 / 670 * 100%);
          margin-top: 45px;

          iframe {
            width: 100%;
            height: 350px;
          }
        }
      }
    }

    @media (max-width: 767px) {


      .address__form {
        margin-top: 40px;

      }

      .contact-numbers {
        flex-wrap: wrap;

        .col-sm-4 {
          min-width: 50%;

          &:nth-last-child(1) {
            margin-top: 30px;
            padding-left: 0;
          }
        }
      }
    }

    .announcement {
      overflow: hidden;
      margin: 40px 300px;
      background-color: #ED5333;
      position: relative;

      .pop-close {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 15;

        svg {
          cursor: pointer !important;
          transition: 0.2s ease-in-out;

          &:hover {
            transform: scale(0.8);
            transition: 0.2s ease-in-out;
          }
        }
      }

      &__data {
        padding: 40px;

        h3 {
          font-size: 36px;
          line-height: 40px;
          font-weight: 500;
          color: #F0EDE3;
          margin-bottom: 20px;
        }

        p {
          font-size: 20px;
          line-height: 20px;
          font-weight: 600;
          color: #F0EDE3;
          margin-bottom: 40px;
        }

        &__img-wrap {
          position: relative;
          padding-top: calc(380 / 660 * 100%);
        }
      }

      @media (max-width: 768px) {
        margin: 120px 15px;
        &__data {
          h3 {
            font-size: 24px;
            line-height: 28px;
          }

          padding: 40px 20px;
        }
      }
    }


    .form-control {
      font-size: 16px;
      line-height: 20px;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid #1C1718;
      border-radius: 0;
      padding-left: 0;
      padding-bottom: 20px;
      margin-bottom: 40px;

      &::placeholder {
        color: #c4c4bc !important;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
      }

      textarea {
        min-height: 120px;
        max-height: 120px;
        min-width: 100%;
        max-width: 100%;
      }

      .form-control {
        width: calc(50% - 15px);
        margin-bottom: 40px;
      }
    }
  }

  .form-divider {
    padding-top: 20px;
    width: 100%;
    display: flex;

    .form-right {
      position: relative;

      &__box {
        height: 44px;
        width: 205px;
        border: none;
        margin-right: 60px;

        &:hover {
          border: none;
        }

        input {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          border: 1px solid #ED5333;
          border-radius: 26px;

          &:focus {
            outline: none;
            border: 1px solid #ED5333;
          }

          &:after {
            background-image: url('/images/static/avatar.svg');
            content: 'Upload Photo';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            bottom: 0;
            transition: all 0.25s ease-in-out;
            background-color: #F0EDE3;
            z-index: 2;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ED5333;
            white-space: normal;
            text-align: center;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: 30px 50%;
            padding-left: 30px;
            transition-delay: 0.15s;

            &:hover {
              background-color: #ED5333;
            }
          }

          input.file-selected:after {
            content: attr(data-file-name);
          }

          &:hover {
            &:after {
              background-image: url('/images/static/avatar-white.svg');
              background-color: #ED5333 !important;
              color: #F0EDE3;
            }
          }
        }

        @media (max-width: 767px) {
          margin-bottom: 40px;
          width: 100%;
        }
      }
    }

    .form-left {
      position: relative;

      &__box {
        height: 44px;
        width: 205px;
        border: none;

        &:hover {
          border: none;
        }

        input {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          border: 1px solid #ED5333;
          border-radius: 26px;

          &:focus {
            outline: none;
            border: 1px solid #ED5333;
          }

          &:after {
            background-image: url('/images/static/cv.svg');
            content: 'Attach CV';
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            bottom: 0;
            transition: all 0.25s ease-in-out;
            background-color: #F0EDE3;
            z-index: 2;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ED5333;
            white-space: normal;
            text-align: center;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: 30px 50%;
            padding-left: 30px;
            transition-delay: 0.15s;
          }

          &:hover {
            &:after {
              background-image: url('/images/static/cv-white.svg');
              background-color: #ED5333 !important;
              color: #F0EDE3;
            }
          }
        }

        @media (max-width: 767px) {
          margin-bottom: 40px !important;
          width: 100% !important;
        }
      }
    }

    @media (max-width: 767px) {
      display: block !important;
    }
  }


  .modal-title {
    h2 {
      font-size: 42px !important;
      font-weight: bold;
      line-height: 42px;
      margin-bottom: 0;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  
  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 500;
      line-height: 20px;
      color: #25292C;
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
      padding: 0;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
     
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }
  
  .css-1u9des2-indicatorSeparator{
    display: none;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      z-index: 2;
    }

    svg {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 100%;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 50%;
    }

    &:hover {
      &:after {
        top: 0;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .menu-desktop {
    background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  //toastify
  .toastify {
    position: fixed;
    z-index: 999999999;
  }

  .toastify {
    z-index: 999999999;
  }


  .Toastify__toast-container {
    z-index: 99999999;
  }


  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  .page-loader {
    position: fixed;
    background-color: rgb(36, 24, 67);
    width: 100vw;
    z-index: 999999999;
    overflow: hidden;
    //opacity: 0;
    //height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //left: 0; //width: 0;
    //transition: all 2.6s ease;
    //.anim-logo {
    //  width: 130px;
    //  overflow: hidden;
    //  height: fit-content;
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  top: 0;
    //  bottom: 0;
    //  opacity: 0;
    //  margin: auto;
    //  z-index: 2;
    //
    //  img {
    //    height: 55px;
    //  }
    //}

    //.hide-logo {
    //  background-color: #010A1A;
    //  width: 50%;
    //  height: 100vh;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  margin: auto;
    //  position: absolute;
    //  z-index: 999;
    //}

    .pre-loader__img {
      //position: fixed;
      height: 100px;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(36, 24, 67);
      z-index: 99999999;

      //flex: 1;
      margin: auto;
      opacity: 0;

      img {
        display: block;
      }

      .top {
        height: 8px;
      }

      .bottom {
        height: 30px;
        margin-top: 5px;
        animation: flip 1s linear infinite;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0deg)
        }
        100% {
          transform: rotateY(180deg)
        }
      }
    }

  }

  //------------------------animation
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //scroll-smooth shaking issue
  #smooth-content, section{
    will-change: transform;
  }

  //page transition
  // .page-transition {
  //   position: fixed;
  //   height: 100%;
  //   width: 100%;
  //   top: 0;
  //   left: 0;
  //   background-color: ${hover};
  //   z-index: 999999999;
  //   display: none;
  //   opacity: 0;
  //   //display: flex;
  //   align-items: center;
  //   justify-content: center;
  //
  //   .logo {
  //     height: 80px;
  //     opacity: 0;
  //   }
  // }

  .page-transition {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #004185;
    z-index: 999999999;
    //display: none;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    .logo {
      height: 80px;
      opacity: 1;
    }
  }




  //career modal 
  .CareerModal {
    z-index: 99999;
    .main_scroll{
      @media(max-width: 767px){
        height: 100% !important;
      }
    }
    .modal-dialog {
      transform: none;
      margin: 0 0 0 auto;
      width: 100%;
      border-radius: 0;

      .container {
        max-width: 90%;
      }

      .modal-content {
        border-radius: 0;
        background: #25292C;

        //.noise {
        //  position: absolute;
        //  inset: 0;
        //  overflow: hidden;
        //  width: 100%;
        //  height: 100%;
        //  background: #25292C;
        //}
        
        .modal-data{
          @media(max-width: 767px){
            flex-direction: column;
            height: -webkit-fill-available;
          }
        }

        .modal-close {
          height: auto;
          width: auto;
          border-radius: 0;
          right: 30px;
        }

        .modal-data__content {
          border: none;
        }

        .modal-main-content {
          height: calc(100vh - 80px);
          overflow-y: scroll;
          padding-bottom: 90px !important;
          
          @media(max-width: 767px){
            height: auto;
            overflow-y: unset;
          }

          &::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for IE, Edge and Firefox */
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
        .close-button{
          position: absolute;
        }
        .left-content{
          margin-top: 80px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none;
          }

          /* Hide scrollbar for IE, Edge and Firefox */
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
        .right-content{
          @media(max-width: 767px){
            margin-bottom: 40px;
          }
        }
      }

      .modal-content-left {
        h5 {
          font-weight: 500;
          color: #FCFEFF;
          text-transform: uppercase;
          padding-bottom: 40px;
          border-bottom: 1px solid rgba(241, 238, 233, 0.2);
        }

        .inner-content {
          color: #fff;
          margin-top: 40px;

          h6 {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: rgb(252 254 255 / 50%);
            margin-bottom: 20px;
          }

          p {
            color: #FCFEFF;
            margin-bottom: 40px;
          }

          ul {
            li {
              font-size: 16px;
              font-weight: 400;
              color: #FCFEFF;
              position: relative;
              padding: 0px 0px 20px 40px;
              z-index: 50;
              position: relative;
              counter-increment: count 1;
              border-bottom: 1px solid rgba(241, 238, 233, 0.2);
              margin-bottom: 20px;

              &:before {
                content: counter(count, decimal);
                position: absolute;
                height: 18px;
                width: 18px;
                margin: 0px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
                border: 1px solid rgba(241, 238, 233, 0.2);;
                background-color: #FCFEFF;
                border-radius: 50%;
                color: #26201E;
                top: 2px;
                left: 0px;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
              }

              &:last-child {
                border: none;
              }
            }
          }
        }
      }

      .modal-data {
        display: flex !important;
        height: calc(100vh - 90px);
        overflow-y: scroll;


        &::-webkit-scrollbar {
          display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }


      .form-wrapper-all {
        margin-top: 90px;
        .form-control {
          margin-bottom: 40px;
          background: transparent;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid rgba(241, 238, 233, 0.5);
          padding-left: 0;
          padding-bottom: 15px;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          color: rgba(241, 238, 233, 1);
        }


        .formContact {


          input {
            &:not(.react-select__input input) {
              line-height: 20px;
              border-radius: 0;
              height: 44px;
              border-color: rgba(255, 255, 255, 0.5);
            }

            padding-left: 0;
            padding-right: 0;

            &::placeholder {
              color: rgba(241, 238, 233, 0.5);
            }
          }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                color: rgba(241, 238, 233, 0.5) !important;
                -webkit-text-fill-color: rgba(241, 238, 233, 0.5) !important;
                //-webkit-box-shadow: 0 0 0 1000px transparent inset !important;
                -webkit-background-clip: text !important;
                background-clip: text !important;
                border: none;
            }

          .react-select__menu {
            z-index: 9;
          }

          .react-select__control--menu-is-open {

          }

          .react-select__indicators {
            display: block;

            .react-select__indicator {
              padding-right: 0;
              padding-left: 0;
              color: white !important;
            }
          }

          .react-select__value-container {
            padding: 0;
            display: block;
          }

          .react-select__single-value {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: rgba(255, 255, 255, 1);
          }

          textarea {
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
            min-height: 100px;
          }

          .form-select {
            .react-select__indicator-separator {
              display: none;
            }

            .react-select__control {
              //height: 48px;
              border-color: rgba(255, 255, 255, 0.5);
              box-shadow: none;
              border-left: none;
              border-top: none;
              border-right: none;
              background: transparent;
              height: 30px;
              margin-bottom: 40px;
            }

            .react-select__placeholder {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: rgba(255, 255, 255, 0.5);
            }
          }

        }


        .max_size {
          font-size: 10px;
          font-weight: 300;
          line-height: 20px;
          color: rgba(34, 34, 34, 0.8);
          margin: 5px 0 0;
        }

        .gph_upload {
          position: relative;
          cursor: pointer;
          border: none !important;
          //border-radius: 25px !important;
          overflow: hidden !important;
          padding: 12px;

          &.hide {
            &:before {
              opacity: 0;
            }
          }

          &:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            content: attr(text);
            border: 1px #FFFFFF;
            //border-radius: 25px;
            border-style: dashed;
            background: #3C3C3B;
            z-index: 1;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.7s all ease;
            padding-left: 20px;
          }

          &:before {
            content: '';
            background-image: url('images/static/attach.svg');
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            z-index: 11;
            left: 25%;
            top: 7px;
          }

          @media (min-width: 1024px) and (max-width: 1280px) {
            &:after {
              padding-left: 20px;
            }

            &:before {
              left: 23%;
            }
          }
          @media (min-width: 1536px) {
            &:before {
              left: 30%;
            }
          }

          &:hover {
            &:after {
              border: 1px ${hover};
              //border-radius: 25px;
              background: ${hover};
            }
          }
        }

        .has-error {
          border-color: #EE1B24 !important;
        }
      }


    }
  }

  .modal_video_popup {
    .modal-video {
      background: transparent;
      position: absolute;
    }

    .modal-dialog {
      &:focus-visible {
        outline: none !important;
      }
    }

    .for-close {
      display: none;
    }

    .modal-dialog, .modal-content {
      background: transparent;
    }
  }

  //sticky menu

  //menu fixed

  .scroll-down .main-menu-desktop, .scroll-down .Mobile-menu-wrap {
    transform: translate3d(0, -105%, 0);
    transition: all 0.5s ease-in-out;

    .header__right__logo {
      p {
        color: black;
        transition: color 0.5s ease;
        transition-delay: 1s;
      }

      .hamburger-icon span {
        background: #000;
        transition: all 0.5s ease;
        transition-delay: 1s;
      }
    }


    .logo {
      svg {
        g {
          #Path_8654 {
            transition: fill 0.5s ease 1s !important;
          }
        }

        #Path_8732 {
          transition: 0.5s fill ease;
        }
      }
    }

  }


  .scroll-up .main-menu-desktop, .scroll-up .Mobile-menu-wrap {
    transition-delay: 10s;
    background-color: #F7F3F0 !important;
    transition: all 0.5s ease-in-out 0s;
    //box-shadow: 0px -7px 27px 3px rgba(0, 0, 0, 0.6);
    //-webkit-box-shadow: 0px -7px 27px 3px rgba(0, 0, 0, 0.6);
    //-moz-box-shadow: 0px -7px 27px 3px rgba(0, 0, 0, 0.6);

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 0;
      left: 0;
      background: #191b1d1a;
    }


    .header__right__logo {
      .dc-btn{
        a{
          border-color: #25292C;
          span{
            color: #25292C;
          }
          &:hover{
            border-color: #3CB649;
            span{
              color: #FCFEFF;
            }
          }
        }
      }
      p {
        color: black !important;
        transition: all 0.1s ease;
      }

      ul {
        a {
          li {
            color: #25292C;

            &:hover {
              color: #3CB649;
            }
          }
        }
      }

      .hamburger-icon {
        span {
          background: #000 !important;
          transition: all 0.1s ease;
        }

        svg {
          g {
            line{
              stroke: #1b1e21;
            }
          }
        }
      }
    }


    .logo {
      svg {
        g {
          #Path_9009 {
            fill: #004185;
            transition: all 0.1s ease;
          }

          #Path_9008 {
            fill: #3CB649;
            transition: all 0.1s ease;
          }

          #Path_9007 {
            fill: #3CB649;
            transition: all 0.1s ease;
          }

          #Path_9006 {
            fill: #3CB649;
            transition: all 0.1s ease;
          }

          #Path_9005 {
            fill: #3CB649;
            transition: all 0.1s ease;
          }

          #Path_9004 {
            fill: #3CB649;
            transition: all 0.1s ease;
          }

          #Path_9003 {
            fill: #3CB649;
            transition: all 0.1s ease;
          }

          #Path_9002 {
            fill: #3CB649;
            transition: all 0.1s ease;
          }

          #Path_9001 {
            fill: #505153;
            transition: all 0.1s ease;
          }

          #Rectangle_5880 {
            fill: #3CB649;
            transition: all 0.1s ease;
          }

          #Rectangle_5881 {
            fill: #3CB649;
            transition: all 0.1s ease;
          }

          path {
            fill: #505153;
            transition: all 0.1s ease;
          }
        }

        #Path_8732 {
          fill: rgb(30, 30, 46);
          transition: 0.5s fill ease;
        }
      }
    }


    //for mobile


    .mobile-menu {
      .hamburger {
        svg {
          g {
            line {
              stroke: black !important;
            }
          }
        }
      }
    }

  }
  .menu-visible{
    position: fixed !important;
    .mobile-menu{
      .hamburger{
        span{
          &:first-child{
            display: none !important;
          }
          &:last-child{
           display: flex !important; 
          }
        }
      }
      .mobile-menu__items{
        overflow-y: scroll;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
      }
    }
  }
  
  .scroll-up .mobile-menu .hamburger{
    svg {
      g {
        line {
          stroke: white !important;
        }
      }
    }
  }
  
  //team popup modal
  .team-modal {
    background-color: #25292C;
    height: 100vh;
    z-index: 99999;
    overflow: hidden !important;
    width: 668px;
    right: 0;
    left: auto;
    padding: 0px 70px 30px 70px !important;

    .close-button {
      right: 70px;
      position: fixed;
      opacity: 0;
      transition-delay: .6s;
    }

    .modal-dialog {
      height: 100vh;
      background-color: transparent;
      min-width: 100%;
      margin: 0;
    }

    .modal-body {
      height: 100vh;
    }

    .modal-content {
      background-color: transparent;
      margin: 80px 0;
      //overflow: scroll;
      //height: calc(100vh - 120px);
      
      .team-modal__content {
        height: calc(100vh - 120px);
        overflow-y: scroll;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        h6 {
          color: #FCFEFF;
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          margin-top: 20px;
          padding-top: 20px;
          margin-bottom: 5px;
        }

        p {
          color: rgba(252, 254, 255, 0.5);
        }
        
        .desc{
          margin-top: 40px;
          color: #FCFEFF;
        }

      }
      .team-modal__content::-webkit-scrollbar {
        display: none;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 0 20px 10px 20px !important;
      .close-button {
        right: 20px;
        position: fixed;
      }

      .modal-content {
        //padding-top: 80px;

        .container {
          //height: calc(100vh - 100px);
          //overflow: scroll;

          h3 {
            margin-top: 30px;
            font-size: 28px;
            line-height: 35px;
          }
        }
      }
      //overflow: scroll !important;

    }
  }
  .modal-open {
    .close-button {
      opacity: 1;
      transition-delay: .6s;
    }
  }


  .simplebar-scrollbar:before {
    background-color: #FFF !important;
  }

  .simplebar-track.simplebar-vertical {
    width: 7px;
  }

  .simplebar-track {
    right: -7px;
  }


  //video modal
  .modal-video {
      background-color: transparent;
      height: 100vh;
      z-index: 99999;

      .modal-dialog {
          height: 100vh;
          background-color: transparent;
          min-width: 100%;
          margin: 0;
      }

      .modal-body {
          height: 100vh;
      }

      .modal-content {
          background-color: transparent;

      }

      iframe {
          height: 60vh;
          width: 60vw;
          margin: auto;
          position: absolute;
          inset: 0;
      }

      .close-modal {
          position: absolute;
          top: 40px;
          right: 30px;
          height: 30px;
          cursor: pointer;
          z-index: 99;
      }

      @media (max-width: 768px) {
          .modal-content {
              //padding: 0 20px;

              iframe {
                  width: 90vw;
                  height: 60vh;
              }

              .close-modal {
                  top: 80px;
                  right: 20px;
              }
          }
      }
      @media (max-width: 550px) {
          .modal-content iframe {
              width: 90vw;
              height: 40vh;
          }
      }
  }
`;



