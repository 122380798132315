import React, {useState} from 'react';
import styled from 'styled-components';
import ReactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom"
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars";

const Box = ({title, text, slider, learn_more, size, best_seller, img, icon, url, hover_img}) => {

    const [show, setShow] = useState(false);
    const [BgHeight, setBgHeight] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const removeExtra = icon;

    return (

        <StyledBox hide={best_seller} className={`box_global`}>
            <div className={'box_wrp'}>
                <div className="box_global_single">
                    <Link to={`/product/${url}`}></Link>
                    <div className="box_global_single__img">
                        <div className="default">
                            <Img src={img ? img  : '/images/dynamic/product.jpg'}/>
                        </div>
                        <div className="content">
                            <p className={'product_title'}>{title ? ReactHtmlParser(title)  : 'Wooden tile'}</p>
                            <p className={'product_size'}>{text ? ReactHtmlParser(text)  : '150X900mm'}</p>
                        </div>
                    </div>
                </div>
            </div>


        </StyledBox>

    )
};


const StyledBox = styled.div`
  height: 100%;
  margin-bottom: 30px;

  .box_global_single {
    cursor: pointer;
    transition: 0.7s all linear;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition-delay: 0.7s;
    overflow: hidden;
    background-color: #fff;

    a {
      position: absolute;
      z-index: 3;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }

    &__img {
      //padding-top: 100%;
      //position: relative;
      //overflow: hidden;
      //transition: none;
      //background-color: #fff;

      //.hover_img {
      //  .global-image img {
      //    transition: none;
      //    opacity: 0;
      //    visibility: hidden;
      //  }
      //}
      padding: 20px;
      .default{
        position: relative;
        padding-top: calc(100%);
      }
      .content{
        margin-top: 20px;
        .product_title{
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: #25292C;
        }
        .product_size{
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
          color: #25292C;
        }
      }
    }

    &__footer {
      position: relative;
      left: 0;
      right: 0;
      bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 20px;
      z-index: 2;

      .content {
        position: relative;
        z-index: 2;
        max-width: 75%;

        h4 {
          font-size: 16px;
          font-weight: 600;
          //color: #FFF;
          margin: 0 0 5px;
          text-transform: capitalize;
        }

        p {
          //color: #FFFFFF;
          font-size: 12px;
          line-height: 14px;
          font-weight: 600;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin: 0;
        }


      }

      .icon {

        display: flex;
        align-items: flex-end;
        max-width: 34%;
        text-align: right;

        h5 {
          font-size: 12px;
          line-height: 14px;
          font-weight: 600;
          max-width: 100%;
        }
      }

    }

    &:after {
      content: attr(data-seller);
      position: absolute;
      top: 0;
      right: 20px;
      z-index: 0;
      opacity: 1;
      transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
      background: #1E5C95;
      color: white;
      height: auto;
      width: auto;
      padding: 15px;
      writing-mode: vertical-rl;
      display: ${props => props.hide ? 'block' : 'none'};
      transform: rotate(180deg);
    }


    &:hover {
      .box_global_single__img {
        //transform: scale(1.04);
        .default {
          .global-image img {
            //opacity: 1;
            //visibility: hidden;
          }
        }

        //.hover_img {
        //  .global-image img {
        //    opacity: 1;
        //    visibility: visible;
        //  }
        //}
      }

      .box_global_single__content {
        h4, p {
            //color:${hover}
          //color: #1E5C95;
        }
      }
    }


  }

`;


export default Box;