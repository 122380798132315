import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css'

const Video = ({img, vId}) => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState(vId);

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

    const [offset, setOffset] = useState()
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])


    return (

        <StyledVideo  className={`video_body `}>
            <ModalVideo channel='youtube' isOpen={open} videoId={videoId} onClose={() => handelOpen(false, '')}/>

            <Container fluid className={'pr-0'}>
                <Row>
                    <Col >
                        <div onClick={() => handelOpen(true, vId)} className="image_video_wrap">
                            <Img src={img ? img : '/images/dynamic/video.jpg'}/>
                            <div className="play">
                                <svg id="Button_-_video" data-name="Button - video" xmlns="http://www.w3.org/2000/svg"
                                     width="90" height="90" viewBox="0 0 90 90">
                                    <circle id="Ellipse_186" data-name="Ellipse 186" cx="45" cy="45" r="45"
                                            fill="#fff"/>
                                    <circle id="Ellipse_187" data-name="Ellipse 187" cx="45" cy="45" r="45"
                                            fill="#fff"/>
                                    <path id="Path_2054" data-name="Path 2054" d="M-7094.428,1397.423l7.5,7.5-7.5,7.5Z"
                                          transform="translate(7135.928 -1359.923)" fill="#004185"/>
                                </svg>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledVideo>

    )
};


const StyledVideo = styled.div`
  position: relative;
  background: #F7F3F0;
  @keyframes zoomIt {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      transform: scale(1.09);
    }
    80% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }

  }

  .container-fluid {
    padding-left: ${props => props.offset ? props.offset + 15 + 'px' : ''};
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
      padding-left: 15px !important;
      padding-right: 15px !important;
      .col-md-12 {
        padding: 0;
      }
    }
  }
  
  .col{
    padding: 0;
  }


  .image_video_wrap {
    padding-top: calc(660 / 1168 * 100%);
    cursor: pointer;
    overflow: hidden;
    position: relative;

    .play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      height: 90px;
      width: 90px;
      border-radius: 50%;

      &:before {
        content: '';
        position: absolute;
        height: calc(100% + 30px);
        width: calc(100% + 30px);
        border: none;
        background: rgba(255, 255, 255, 0.5);
        left: -15px;
        top: -15px;
        border-radius: 50%;
        opacity: 0;
        transition: 0.6s all cubic-bezier(0.4, 0, 0, 1);
        z-index: -4;
      }

      &:after {
        content: "";
        z-index: 0;
        //background-color: rgba(30, 92, 149, 0.5);
        overflow: hidden;
        border-radius: 50%;
        transition: 0.6s all cubic-bezier(0.4, 0, 0, 1);
      }
    }

    .global-image {
      img {
        transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        transform: scale(1.01);
      }
    }

    @media (max-width: 767px) {
      padding-top: calc(500 / 468 * 100%);
    }

    &:hover {
      .global-image {
        img {
          transform: scale(1.04);

        }

      }

      .play {
        &:before {
          animation: zoomIt cubic-bezier(0.4, 0, 0, 1) 1;
          animation-duration: 0.9s;
          animation-direction: alternate-reverse;
          animation-iteration-count: infinite;
        }

        &:after {
          opacity: 0;
        }

      }
    }
  }
  .modal-video{
    position: absolute;
  }
`;


export default React.memo(Video);














