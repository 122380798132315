import React from 'react';
import styled from "styled-components";
import {Container, Row, Col, Table} from "react-bootstrap";
import {title} from '../../styles/globalStyleVars';
import reactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col>
                        <h3>{reactHtmlParser(data?.comparison?.title)}</h3>
                        {reactHtmlParser(data?.comparison?.desc)}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color:#004185;
  
  h3{
    color: #FAFAFA;
    width: 60%;
    margin-bottom: 60px;
    @media(max-width: 767px){
      width: 100%;
    }
  }
  table {
    margin-bottom: 0;

    tr {
      &:first-child{
        td{
          border-color: #FCFEFF;
        }
      }
      td {
        font-family: ${title};
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #FCFEFF;
        width: 50%;
        padding-left: 0;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-color: rgba(252, 254, 255, 0.2);
        border-bottom: 1px solid rgba(184, 184, 184, 0.40);
      }

      &:nth-of-type(1) {
        td {
          padding-top: 0;
          border-top: 0;
        }
      }

      &:nth-last-of-type(1) {
        td {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
`;

export default React.memo(MyComponent);
