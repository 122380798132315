import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Container,Row,Col} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {ImgInnerBanner} from "./ImgInnerBanner";

const InnerBanner = ({img,breadcrumbs, subtitle, title,mobImg}) => {

    // Define the images for different viewports
    const largeImage = img?.large;
    const mediumImage = mobImg ? mobImg : img?.medium;
    const smallImage = mobImg ? mobImg : img?.thumbnail;

    // Determine the viewport size and set the image accordingly
    let imgUrl;
    if (window.innerWidth >= 1366) {
        imgUrl = largeImage;
    } else if (window.innerWidth >= 768) {
        imgUrl = mediumImage;
    } else {
        imgUrl = smallImage;
    }

    return (
        <StyledInnerBanner className='InnerBanner'>
            <ImgInnerBanner src={imgUrl} banner/>
            <Container>
                <Row>
                    <Col>
                        <p className={'breadcrumbs split-up-title-fast'}>{reactHtmlParser(breadcrumbs)}</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <h1 className={`anim-active fade-up split-up-title-fast`}>{reactHtmlParser(title)}</h1>
                    </Col>
                    <Col sm={{span:4, offset:2}}>
                        <p className={'sub-title split-up-title-fast'}>{reactHtmlParser(subtitle)}</p>
                    </Col>
                </Row>
            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(560 / 1366 * 100%);
  position: relative;
  background-color: #DDD;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.25);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    //height: 100%;
    //top: 0;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 71px;
    z-index: 2;
  }


  h1 {
    left: 15px;
    color: #FAFAFA;
    font-size: 40px !important;
    font-weight: 400 !important;
    line-height: 48px !important;
    padding-bottom: 20px;
    z-index: 2;

    @media (min-width: 768px) and (max-width: 991px){
      font-size: 42px;
      font-weight: 400;
      line-height: 42px;
    }

    span {
      font-weight: 600;
      color: #ffffff;
    }
  }
  .breadcrumbs{
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .sub-title{
    color: #ffffff;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
  }

  @media (min-width: 767px) {
    .title {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    //padding-top: 0;
    padding-top: calc(680 / 414 * 100%);
    .container {
      bottom: 69px;
    }

    .title {
      margin-bottom: 40px !important;
    }
    
  }

  @media (min-width: 768px) and (max-width: 991px){
    padding-top: calc(750 / 1366 * 100%);
    position: relative;
  }

`;

export default React.memo(InnerBanner);
