import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import TeamSingle from "../about/TeamSingle";
import {hover, whites} from "../../styles/globalStyleVars";
import SimpleBar from "simplebar-react";
import CloseModal from "../CloseModal";
import CloseButton from "../CloseButton";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    const [show, setShow] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState(null);

    const handleClose = () => {
        setShow(false);
        setSelectedTeamMember(null); // Clear the selected team member data when closing the modal
    };
    const handleShow = (teamMember) => {
        setSelectedTeamMember(teamMember); // Set the selected team member data
        setShow(true);
    };

    const handleTeamMemberClick = (e) => {
        handleShow(); // Open the modal
        // You can do something with the 'e' data here, if needed
    };

    return (
        <StyledComponent className={'management-team pb-120'} id={'team'}>
            <Container>
                <h3>{data?.title}</h3>
                <Row>
                    {
                        data?.team_members && data?.team_members?.length > 0 &&
                        data?.team_members?.map((e,i)=>{
                            return(
                                <Col md={4} sm={4} onClick={() => handleShow(e)} key={i}>
                                    <TeamSingle title={e?.name} deg={e?.designation}
                                                img={e?.image} />
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>

            <Modal className={'team-modal'} show={show} onHide={handleClose}>
                <div onClick={handleClose}>
                    <CloseButton/>
                </div>
                <SimpleBar style={{maxHeight: window.innerHeight - 150 + 'px'}}>
                    <div className="team-modal__content">
                        <img src={selectedTeamMember?.image} alt=""/>
                        <h6>{selectedTeamMember?.name}</h6>
                        <p>{selectedTeamMember?.designation}</p>
                        <p className={'desc'}>{reactHtmlParser(selectedTeamMember?.details)}</p>
                    </div>
                </SimpleBar>

            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F7F3F0;
  
  h3{
    margin-bottom: 60px;
  }

  .team-single {
    margin-bottom: 60px;

    &__content {
      border-color: rgba(245, 245, 245, 0.5);

      h6 {
        color: #25292C;
      }

      p {
        color: rgba(37, 41, 44, 0.5);
      }
    }

    @media (max-width: 600px) {
      margin-bottom: 40px;
    }
  }
`;

export default MyComponent;
