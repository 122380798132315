import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import NavigationIcon from "../NavigationIcon";
import 'swiper/css';
import "swiper/css/pagination";
import 'swiper/css/effect-fade';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade, Navigation} from "swiper";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";


const CoreValues = ({data}) => {

    return (
        <StyledComponent className={'our-team  pb-200 pt-200'}>
            <Container>
                <Row>
                    <Col md={7}>
                        {
                            window.innerWidth <= 768 &&
                            <div  className="title-left">
                                <h2 className={'split-up'}>
                                    {data?.title ? reactHtmlParser(data?.title) : ''}
                                </h2>
                            </div>
                        }
                        {
                            data?.value &&
                            <Swiper

                                effect={"fade"}
                                modules={[Autoplay, Navigation, EffectFade]}
                                navigation={{
                                    prevEl: '#value-prev',
                                    nextEl: '#value-next',
                                }}
                                speed={900}
                            >
                                {
                                    data?.value?.length > 0 &&
                                    data?.value?.map((e, i)=>{
                                        return(
                                            <SwiperSlide key={i}>
                                                <div className="image-wrapper reveal">
                                                    <Img src={e?.image}/>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        }
                    </Col>
                    <Col md={5} className={'left-column-wrapper'}>
                        {
                            window.innerWidth > 768 ?
                                <div className="title-left ">
                                    <h3 className={'split-up'}>
                                        {data?.title ? reactHtmlParser(data?.title) : ''}
                                    </h3>
                                </div>
                                : ''
                        }


                        <div className="slider-wrapper box-up">
                            {
                                data?.value &&
                                <Swiper

                                    modules={[Autoplay, Navigation]}
                                    navigation={{
                                        prevEl: '#value-prev',
                                        nextEl: '#value-next',
                                    }}
                                    speed={900}
                                >
                                    {
                                        data?.value?.length > 0 &&
                                        data?.value?.map((e,i)=>{
                                            return(
                                                <SwiperSlide key={i}>
                                                    <div className="content">

                                                        <h6>{reactHtmlParser(e?.title)}</h6>
                                                        <p>{reactHtmlParser(e?.desc)}</p>
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        })
                                    }
                                </Swiper>
                            }
                            <NavigationIcon color={'#F9F5F2'} next_id={'value-next'} prev_id={'value-prev'}/>

                        </div>
                    </Col>

                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: #FCFEFF;
  position: relative;

  .image-wrapper {
    padding-top: calc(768 / 670 * 100%);
    position: relative;
  }


  .left-column-wrapper {
    display: flex;
    position: relative;

    .title-left {
      width: 100%;
      padding-left: 130px;
      //margin-bottom: 90px;
      padding-top: 130px;

      h3 {
        margin: 0;
      }
    }

    .slider-wrapper {
      position: absolute;
      right: 0;
      max-width: calc(100% + 100px);
      width: calc(100% + 100px);
      height: 360px;  
      z-index: 2;
      bottom: 50px;
      background: #3CB649;
      padding: 40px;

      h6 {
        color: #FCFEFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 40px;
      }

      p {
        color: #FCFEFF;
        font-weight: 400;
      }
    }
  }


  .navigation_button {
    margin-top: 70px;
    display: flex;
    justify-content: flex-end;
  }

  .swiper-button-disabled{
    opacity: unset !important;
  }

  @media (max-width: 768px) {

    .col-md-7, .col-md-5 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .title-left {
      margin-bottom: 45px;
    }

    .left-column-wrapper {
      display: block;
    }

    .slider-wrapper {
      padding: 40px 25px !important;
      position: relative !important;
      right: 0 !important;
      max-width: 100% !important;
      width: 100% !important;
    }

    .navigation_button {
      margin-top: 30px !important;
      justify-content: flex-start;
    }
  }
`;

export default React.memo(CoreValues);
