import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import ProjectBox from "./ProjectBox";


const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pb-200'}>
            <Container>
                <Row>
                    {
                        data && data?.length> 0 &&
                        data?.map((e, i)=>{
                            return(
                                <Col md={4} key={i}>
                                    <ProjectBox
                                        text={e?.hover_text}
                                        title={e?.post_title}
                                        img={e?.thumb}
                                        url={e?.post_name}
                                        subtitle={i+1}
                                    />
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 80px;
  background-color: #F7F3F0;
    .col-md-4{
      margin-bottom: 30px;
    }
  .col-md-4:nth-last-child(-n+3) {
    margin-bottom: 0;
    @media(max-width: 767px){
      margin-bottom: 30px;
    }
  }
`;

export default React.memo(MyComponent);
