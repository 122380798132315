import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = true
        },
        loaded: (state) => {
            state.globalLoader = false
        }
    },
    extraReducers: {
        ['fetchAboutData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCareerData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['careerForm/fulfilled']: (state, action) => {
            state.globalLoader = false
        },

        ['fetchContactData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['contactForm/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchEducateData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchFaqData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHomeData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaDataDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProductData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProductDataDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProjectData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProjectDataDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchRefundData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchOrderData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
    }
})


export default globalSlice.reducer
export const {loaded, loading} = globalSlice.actions