import React, {useState,useRef} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import SimpleBar from 'simplebar-react';

import ModalVideo from "react-modal-video";
import Modal from "react-bootstrap/Modal";
import {title} from "../../styles/globalStyleVars";

const ProjectList = ({data,img,date,title}) => {

    return (
        <StyledProjectList className={'gallery'}>
            <div className='gallery__single' >
                <div className='gallery__single__img'>
                    <Img src={img}/>
                    <div className="gallery__single__img__click hover">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
                            <path id="Polygon_1" data-name="Polygon 1" d="M9.168,1.248a1,1,0,0,1,1.664,0l8.131,12.2A1,1,0,0,1,18.131,15H1.869a1,1,0,0,1-.832-1.555Z" transform="translate(15) rotate(90)" fill="#004185"/>
                        </svg>

                    </div>
                </div>
                <div className='gallery__single__content'>
                    <h6>{title}</h6>
                </div>
            </div>
        </StyledProjectList>
    );
};
const StyledProjectList = styled.section`

  margin-bottom: 40px;
  .gallery {
    background: #FFFFFF;

    &__single {
      padding-bottom: 60px;

      &:nth-last-child(1) {
        padding-bottom: 0;
      }

      &:nth-last-child(2) {
        padding-bottom: 0;
      }

      &__img {
        position: relative;
        padding-top: calc(235 / 370 * 100%);
        overflow: hidden;
        cursor: pointer;

        a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          z-index: 2;

          &:nth-child(n + 2) {
            display: none;
          }
        }

        img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          z-index: 1;
          transform: scale(1.01);
          transition: transform 0.6s ease;
        }

        &__click {
          position: absolute;
          z-index: 99999;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 99999;
          height: 80px;
          width: 80px;
          border-radius: 50%;
          background-color: #FCFEFF;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            position: absolute;
            .video-icon-hover {
              transition: all 0.1s ease;
            }
          }
          &:hover{
            svg{
              path{
                fill: white;
              }
            }
          }
        }

      }

      &__content {
        cursor: pointer;
        h6 {
          font-size: 16px;
          line-height: 20px;
          font-weight: 500;
          color: #25292C;
          margin: 20px 0 0 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
        
        h3 {
          color: black;
        }
      }
    }

    @media (max-width: 767px) {
      margin-bottom: 30px;
      &__single {
        &:nth-last-child(2) {
          padding-bottom: 60px;
        }

        &__img {
          padding-top: calc(250 / 345 * 100%);
        }
      }
    }
  }
`;

export default React.memo(ProjectList);